import Box from "@mui/material/Box";
import * as React from "react";
import {useAppState} from "../../state/AppStateProvider";
import {useTheme, Card, CardContent, Typography, CircularProgress} from "@mui/material";
import {PageContentContainer} from "../styled/styled";
import useSWR from "swr";
import {CreditPlan, getCreditPackages, purchaseCreditPackage} from "../../api/hasura";
import {LoadingButton} from "@mui/lab";

const getDisplayCurrency = (currency: string) => {
    switch (currency) {
        case "USD":
            return "$";
        case "EUR":
            return "€";
        default:
            return currency;
    }
}
export const BuyCredits = () => {
    const {setSnackBar} = useAppState();
    const theme = useTheme();


    const [purchasingPlanId, setPurchasingPlanId] = React.useState<null | string>(null);

    const {
        data: creditPackages,
        isLoading: isCreditBalanceLoading
    } = useSWR(`multiverse-credit-plans`, getCreditPackages);


    const handlePurchase = async (plan: CreditPlan) => {

        setPurchasingPlanId(plan.id);
        try {
            console.log(`Purchasing plan: ${plan.name}`);
            const purchaseUrl = await purchaseCreditPackage(plan.id);
            console.log("res", purchaseUrl);

            window.open(purchaseUrl, "_self");
        } catch (err: any) {
            console.error(err);
            setSnackBar({open: true, message: "Could not process purchase, please contact support", severity: "error"});
        } finally {
            setPurchasingPlanId(null);
        }
    };


    return (
        <PageContentContainer>
            <Box sx={{padding: theme.spacing(4)}}>
                <h2>
                    Purchase Multiverse Credits
                </h2>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 4,
                        justifyContent: 'center',
                    }}
                >
                    {isCreditBalanceLoading ? (
                        <CircularProgress sx={{m: 4}} />
                    ) : (
                        creditPackages?.map((plan, index) => (
                            <Card key={index} sx={{
                                width: '320px',
                                padding: theme.spacing(2),
                                border: "1px solid #e0e0e0",
                                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px"
                            }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom sx={{fontWeight: "bold"}}>
                                        {plan.name}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom sx={{marginBottom: theme.spacing(2)}}>
                                        {plan.description}
                                    </Typography>

                                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center"}}>
                                        <div style={{fontSize: "28px", padding: "4px", fontWeight: "bold", color: "grey"}}>{getDisplayCurrency(plan.currency)}</div>
                                        <div style={{fontSize: "42px", fontWeight: "bold"}}>{plan.price}</div>
                                    </Box>
                                    <Typography variant="body1" color="textPrimary" sx={{my: 2}}>
                                        {plan.credits} Multiverse Credits
                                    </Typography>
                                    <LoadingButton
                                        sx={{
                                            marginTop: theme.spacing(2),
                                            padding: theme.spacing(1.5, 3),
                                            fontWeight: "bold"
                                        }}
                                        loading={purchasingPlanId === plan.id}
                                        disabled={!!purchasingPlanId}
                                        variant="contained"
                                        fullWidth
                                        onClick={() => handlePurchase(plan)}
                                    >
                                        Purchase
                                    </LoadingButton>
                                </CardContent>
                            </Card>
                        ))
                    )}
                </Box>
            </Box>
        </PageContentContainer>
    );
};
