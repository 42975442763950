//TODO: add frontend type
import Box from "@mui/material/Box";
import AlmanakChart from "../../../../../components/almanak-chart/AlmanakChart";
import * as React from "react";
import {groupByCategory} from "./AgentsConfigurationContent";
import {styled} from "@mui/material";

const ResponsiveBox = styled(Box)`
  display: flex;
  margin: 24px;
  gap: 24px;

  flex-wrap: wrap;
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 16px;
  }
`
export const AgentConfigurationDoughnutChart = (props: { availableConfigs: any }) => {

    const {availableConfigs} = props;
    const agentTypes = groupByCategory("type", availableConfigs);
    const agentStrategies = groupByCategory("class", availableConfigs);
    const agentTypeLabels = Object.keys(agentTypes);
    const agentStrategiesLabels = Object.keys(agentStrategies);
    let totalAgents = 0;

    const agentStrategiesData = agentStrategiesLabels.map((key, index) => {
        totalAgents += agentStrategies[key].length;

        return agentStrategies[key].length
    });
    const agentTypesData = agentTypeLabels.map((key, index) => {
        return agentTypes[key].length
    });

    const agentStrategiesColors = ["#EF8F3B", "#D25F5D", "#469CB7", "#4D5B9E"];
    const agentTypesColors = ["#80D7A6", "#5E7F9B", "#B3637A", "#DEDA7E"];

    const data = {
        labels: agentTypeLabels,
        datasets: [
            {
                label: 'Strategies',
                data: agentStrategiesData,
                backgroundColor: agentStrategiesColors,
                borderWidth: 0,
            },
            {
                label: 'Agents',
                data: agentTypesData,
                backgroundColor: agentTypesColors,
                borderWidth: 1,
            }
        ]
    };

    const options = {
        responsive: true,
        cutout: "50%",
        plugins: {
            tooltip: {
                display: false
            },
            legend: {
                display: false
            }
        }
    };

    const RenderLabelRow = (label: string, rowCount: number, color: string) => {
        return <Box key={label} sx={{fontSize: "12px", display: "flex", gap: 1}}>
            <Box>
                <span style={{fontWeight: "bold"}}>{(rowCount / totalAgents * 100).toFixed(0)}%</span>
                <span> ({rowCount})</span>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                <div style={{height: "12px", width: "12px", borderRadius: "2px", backgroundColor: color}} />
                <span>{label}</span>
            </Box>
        </Box>
    };

    return <ResponsiveBox>
        <Box>
            <Box sx={{
                height: 0,
                width: "52px",
                display: "flex",
                textAlign: "center",
                flexDirection: "column",
                position: "relative",
                top: "52px",
                left: "52px"
            }}>
            <span style={{fontSize: "20px", fontWeight: "bold"}}>
                {totalAgents}
            </span>
                <span style={{fontSize: "12px"}}>Agents</span>
            </Box>
            <AlmanakChart height={"160px"} type={"doughnut"} data={data} options={options}/>
        </Box>
        <Box sx={{display: "flex", paddingBottom: 2, flexDirection: "column", gap: 1}}>
            <span style={{fontSize: "14px", fontWeight: "bold"}}>By Strategy</span>
            {agentStrategiesLabels.map((label, i) => RenderLabelRow(label, agentStrategies[label].length, agentStrategiesColors[i]))}
        </Box>
        <Box sx={{display: "flex", paddingBottom: 2, flexDirection: "column", gap: 1}}>
            <span style={{fontSize: "14px", fontWeight: "bold"}}>By Agent Type</span>
            {agentTypeLabels.map((label, i) => RenderLabelRow(label, agentTypes[label].length, agentTypesColors[i]))}
        </Box>
    </ResponsiveBox>;
}
