import {PageContentContainer} from "../styled/styled";
import {Box, Button, Paper, TableContainer} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {CenteredColumnFlexBox, FlexBox} from "../simulation/create-simulation/styled";
import {mockWalletsData} from "./WalletsOverview";
import Divider from "@mui/material/Divider";
import {formatMonetaryValue} from "../simulation/create-simulation/PoolDetails";
import Chip from "@mui/material/Chip";
import {ChainBadge} from "./ChainBadge";
import {formatPubKey} from "../../utils/commonUtils";
import {DataGrid} from "@mui/x-data-grid";

const transactions = [
    {type: "Deposit", amount: "1.5 ETH", date: "2024-06-01", status: "Completed"},
    {type: "Withdraw", amount: "2,400.00 USDC", date: "2024-05-27", status: "Completed"},
    {type: "Deposit", amount: "3,400.00 USDC", date: "2021-10-03", status: "Completed"},
];


const columns = [
    {field: 'asset', headerName: 'Asset', flex: 1},
    {field: 'amount', headerName: 'Amount', flex: 1, renderCell: (item: any) => <span>{formatMonetaryValue(item.row.amount)}</span>},
    {field: 'usdValue', headerName: 'USD Value', flex: 1, renderCell: (item: any) => <span>${formatMonetaryValue(item.row.usdValue)}</span>},
];

export const walletHoldingsMock = [
    {id: 1, asset: "ETH", amount: 10, usdValue: 35000.11},
    {id: 2, asset: "BTC", amount: 0.5, usdValue: 35000.12},
    {id: 3, asset: "USDC", amount: 10000, usdValue: 10001.47},
    {id: 4, asset: "LINK", amount: 1000, usdValue: 13000.24},
    {id: 5, asset: "AAVE", amount: 5, usdValue: 530.4},
];


export const WalletDetails = () => {

    const navigate = useNavigate()


    const onHoldingSelect = (holding: any) => {
        console.log("Holding selected", holding);
    }

    const selectedWallet = mockWalletsData[0];

    return (
        <PageContentContainer sx={{py: 2}}>
            <FlexBox sx={{alignItems: "center", justifyContent: "space-between"}}>
                <FlexBox sx={{alignItems: "center", gap: "16px"}}>
                    <Button variant="contained"
                            onClick={() => navigate(-1)}
                            startIcon={<ArrowBackIosNew/>}>Back</Button>
                    <h2>{selectedWallet.name}</h2>
                </FlexBox>

                <FlexBox sx={{gap: 2}}>
                    <Button variant={"outlined"}>Witdraw</Button>
                    <Button variant={"contained"}>Deposit</Button>
                </FlexBox>
            </FlexBox>

            <Divider/>

            {/*Upper side of wallet overview*/}
            <FlexBox sx={{justifyContent: "space-between", width: "100%", gap: 4}}>

                {/*Wallet holdings side*/}
                <CenteredColumnFlexBox sx={{alignItems: "center", width: "100%"}}>
                    <h1 style={{textAlign: "center"}}>${formatMonetaryValue(selectedWallet.usdValue)}</h1>

                    <FlexBox sx={{gap: 4}}>
                        <CenteredColumnFlexBox sx={{gap: 2}}>
                            <div>Status</div>
                            <div>Type</div>
                            <div>Chain</div>
                            <div>Address</div>
                        </CenteredColumnFlexBox>

                        <Box sx={{borderRight: "1px solid #eaeaea"}}/>

                        <CenteredColumnFlexBox sx={{gap: 2}}>
                            <Chip label={selectedWallet.status}/>
                            <div>{selectedWallet.type}</div>
                            <ChainBadge chain={selectedWallet.chain}/>
                            <div>{formatPubKey(selectedWallet.address)}</div>
                        </CenteredColumnFlexBox>


                    </FlexBox>
                </CenteredColumnFlexBox>

                <Box sx={{borderRight: "1px solid #eaeaea"}}/>


                {/*Transactions side*/}
                <CenteredColumnFlexBox sx={{alignItems: "center", width: "100%"}}>
                    <h3 style={{textAlign: "center"}}>Transactions</h3>


                    <CenteredColumnFlexBox sx={{alignItems: "center", width: "100%", gap: 2}}>

                        {transactions.map((transaction, index) => (
                            <FlexBox key={index} sx={{
                                gap: 2,
                                width: "100%",
                                justifyContent: "space-between",
                                border: "1px solid #eaeaea",
                                p: "8px 16px",
                                borderRadius: "8px"
                            }}>
                                <FlexBox sx={{gap: 1}}>
                                    <div>{transaction.type}</div>
                                    <div>{transaction.amount}</div>
                                </FlexBox>
                                <CenteredColumnFlexBox sx={{gap: 1}}>
                                    <div>{transaction.date}</div>
                                </CenteredColumnFlexBox>
                            </FlexBox>
                        ))}
                    </CenteredColumnFlexBox>
                </CenteredColumnFlexBox>

            </FlexBox>

            <Divider  sx={{my: 2}}/>

            <TableContainer component={Paper} sx={{my: 2}}>

                {walletHoldingsMock && <DataGrid
                    onRowClick={(item) => onHoldingSelect(item.row)}
                    rows={walletHoldingsMock}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10},
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                />}
            </TableContainer>

        </PageContentContainer>
    )
}
