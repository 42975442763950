// @ts-nocheck
import {PanelBox} from "../../styled";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState, useEffect} from "react";
import {HeaderComponent, TitleValueRow} from "./ReviewSummaryContent";
import {initialMarketScenarioOptions} from "../constants";
import {useAppState} from "../../../../../state/AppStateProvider";
import {styled, SxProps, Theme} from "@mui/material";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LoadingButton from "@mui/lab/LoadingButton";
import {createGroupSimulation, GroupSimulation, getCostAndDurationEstimate} from "../../../../../api/hasura";
import {useNavigate} from "react-router-dom";
import {AgentConfigurationDoughnutChart} from "../agents/AgentConfigurationDoughnutChart";
import {formatDateValue} from "../../PoolDetails";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import {ESimulationStatus} from "../../../../../state/types";
import Chip from "@mui/material/Chip";


dayjs.extend(relativeTime)
dayjs.extend(duration)

const ResponsiveBox = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 600px) {
        flex-direction: column;
    }
`

type SimulationInputParametersProps = {
    selectedSimulation: GroupSimulation;
    isEditable?: boolean;
    sx?: SxProps<Theme>;
    setValue?: any;
};
export const SimulationInputParameters = ({
                                              // selectedSimulation,
                                              isEditable,
                                              sx,
                                              setValue
                                          }: SimulationInputParametersProps) => {

    const {selectedSimulation} = useAppState();
    const navigate = useNavigate();
    const [isCloning, setIsCloning] = useState(false);
    const {simulatorPriceTrajectoryHashes, availableTokenPairs, setSelectedSimulation, user, setSnackBar} = useAppState();
    const [estimatedCost, setEstimatedCost] = useState<string | null>(null);
    const [estimatedDuration, setEstimatedDuration] = useState<string | null>(null);
    const {
        price_configs,
        agent_configs
    } = selectedSimulation ?? {};

    //console.log("selectedSimulation selectedSimulation", selectedSimulation);
    //console.log("selectedSimulation", selectedSimulation);
    const frontendAgentConfigs = selectedSimulation?.frontend_state.agentConfigs;
    const frontendPriceConfigs = selectedSimulation?.frontend_state?.priceConfigs;
    const agentConfigs = frontendAgentConfigs ?? agent_configs?.[0]?.agents ?? [];
    const selectedMarketScenarios = selectedSimulation?.frontend_state?.selectedMarketScenarios ?? [];
    const marketScenariosCount = selectedMarketScenarios?.filter(s => s.selected)?.length;

    const editStep = (stepIndex: number) => {
        setSelectedSimulation(prevState => {
            setValue("activeStep", stepIndex);
            if (prevState) {
                return {...prevState, frontend_state: {...prevState.frontend_state, activeStep: stepIndex}}
            }
        })
    };

    const getMarketScenarioTitle = (id: string) => {
        return initialMarketScenarioOptions.find(s => s.type === id)?.title ?? "-"
    }

    const cloneSimulation = async () => {
        setIsCloning(true);
        try {
            const {
                id,
                status,
                aggregated_metrics,
                created_at,
                updated_at,
                started_at,
                finished_at,
                status_reason,
                system_notes,
                simulationMetricsUri,
                ...simulationData
            } = selectedSimulation ?? {};
            const createdSimulation = await createGroupSimulation({
                ...simulationData ?? {},
                status: ESimulationStatus.Pending,
                frontend_state: {
                    ...simulationData.frontend_state,
                    title: `${simulationData.frontend_state.title} (Copy)`,
                    activeStep: 0
                }
            }, user!);
            if (createdSimulation.data.data.insert_group_simulations_one.id) {
                const simulationData = createdSimulation.data.data.insert_group_simulations_one;
                setSelectedSimulation({
                    ...simulationData,
                    frontend_state: {...simulationData.frontend_state, activeStep: 0}
                })
                navigate(`/simulation/${createdSimulation.data.data.insert_group_simulations_one.id}`)
                setSnackBar({open: true, severity: "success", message: "Simulation cloned"})
            } else if (createdSimulation.data.data.errors) {
                //console.log("ERROR!", createdSimulation.data.data.errors);
                setSnackBar({open: true, severity: "error", message: createdSimulation.data.data.errors[0].message})
            }
            setIsCloning(false);
        } catch (err: any) {
            setSnackBar({open: true, severity: "error", message: `Could not clone simulation: ${err.message}`})
            setIsCloning(false);

        }
    };
    const loadEstimatedCostAndDuration = async (id: string) => {
        const results = await getCostAndDurationEstimate(id);
        setEstimatedCost(results.data.data.getCostAndDurationEstimate.estimated_cost);
        setEstimatedDuration(results.data.data.getCostAndDurationEstimate.estimated_duration);
    }

    useEffect(() => {
        loadEstimatedCostAndDuration(selectedSimulation?.id!)
    }, [selectedSimulation])

    //console.log("selectedSimulation", selectedSimulation);
    const simulationRangeDiff = dayjs(selectedSimulation?.frontend_state?.simulation_end).diff(selectedSimulation?.frontend_state?.simulation_start) / 1000;
    const simulationRuntimeDiff = dayjs(selectedSimulation?.finished_at).diff(selectedSimulation?.started_at) / 1000;
    const daysMultiplier = 60 * 60 * 24;
    const simulationRangeDiffInDays = simulationRangeDiff / daysMultiplier;
    const formattedSimulatedRange = dayjs.duration(simulationRangeDiffInDays, "days").format(`${simulationRangeDiffInDays > 365 ? "Y[ years]" : ""} ${simulationRangeDiffInDays > 28 ? "M[ months]" : ""} D[ days]`)

    const minutes = Math.floor(simulationRuntimeDiff / 60);
    const runtimeDuration = dayjs.duration({minutes}).format(`m[ minutes]`);

    const historicalDiffInS = dayjs(selectedSimulation?.frontend_state?.historical_end).diff(selectedSimulation?.frontend_state?.historical_start) / 1000;
    const historicalDiffInDays = historicalDiffInS / 60 / 60 / 24;
    const formattedHistoricalRange = dayjs.duration(historicalDiffInDays, "days").format(`${historicalDiffInDays > 365 ? "Y[ years]" : ""} ${historicalDiffInDays > 28 ? "M[ months]" : ""} D[ days]`)

    return <Box sx={{flexGrow: 1, ...sx}}>
        {!isEditable ?
            <LoadingButton loading={isCloning} onClick={cloneSimulation} sx={{my: 3}} variant="contained" fullWidth
                           startIcon={<RestartAltIcon/>}>Clone, Edit & Re-Run</LoadingButton> : null}


        <PanelBox sx={{p: 2, marginBottom: 2, height: "auto", width: "100%"}}>
            <HeaderComponent noBorder title="Agents Configuration" onEdit={isEditable ? () => editStep(1) : undefined}/>

            <AgentConfigurationDoughnutChart availableConfigs={agentConfigs ?? []}/>
        </PanelBox>

        <ResponsiveBox sx={{display: "flex", gap: 2}}>
            <ResponsiveBox sx={{display: "flex", flexDirection: "column", gap: 2, flexWrap: "wrap", flex: 1}}>
                <PanelBox sx={{p: 2, marginBottom: 2, flex: 1}}>
                    <HeaderComponent title="Selected Asset Pairs" onEdit={isEditable ? () => editStep(0) : undefined}/>
                    {selectedSimulation?.frontend_state?.assetPair?.map((value: any) => (
                        <div><Chip
                            sx={{my: 1}}
                            key={value}
                            label={availableTokenPairs?.find((tokenPair: any) => tokenPair.symbol === value)?.token0_name + " - " + availableTokenPairs?.find(tokenPair => tokenPair.symbol === value)?.token1_name ?? value}
                        /></div>
                    ))}
                </PanelBox>

                <PanelBox sx={{p: 2, marginBottom: 2, flex: 1}}>
                    <HeaderComponent title="Price Trajectories" onEdit={isEditable ? () => editStep(0) : undefined}/>
                    {<TitleValueRow title={"No. of Price Trajectories"} value={`${price_configs?.length ?? selectedSimulation?.frontend_state?.priceConfigs?.length}`}/>}
                </PanelBox>

                {selectedMarketScenarios?.length > 0 && <PanelBox sx={{p: 2, marginBottom: 2, flex: 1}}>
                    <HeaderComponent noBorder title="Market Scenario(s)"
                                     onEdit={isEditable ? () => editStep(0) : undefined}/>
                    {selectedMarketScenarios?.filter(s => s.selected).map((scenario, i) =>
                        <Box key={scenario.title} sx={{py: 1, borderTop: "1px solid #D1D5DB"}}>
                            <TitleValueRow bold title={`Market Scenario ${i + 1}`}
                                           value={getMarketScenarioTitle(scenario.type)}/>
                            {scenario.drift?.[0] && <TitleValueRow title={`Drift value`}
                                                                   value={`${scenario.drift[0]} ↔ ${scenario.drift[1]}`}/>}
                            <TitleValueRow title={`Volatility`} value={scenario.volatilityMultiplier}/>
                        </Box>
                    )}
                </PanelBox>}
            </ResponsiveBox>

            <ResponsiveBox sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                flexWrap: "wrap",
                flex: 1,
                height: "fit-content"
            }}>


                {selectedSimulation?.frontend_state?.simulation_start && <PanelBox sx={{p: 2, marginBottom: 2, flex: 1}}>
                    <HeaderComponent title="Simulation Horizon" onEdit={isEditable ? () => editStep(0) : undefined}/>
                    <Box sx={{py: 1, borderTop: "1px solid #D1D5DB"}}>
                        <TitleValueRow bold title={`Historical Data Date Range`}
                                       value={""}/>
                        <TitleValueRow title={`Range`} value={`${formattedHistoricalRange}`}/>
                        <TitleValueRow title={`Start Date`}
                                       value={`${formatDateValue(selectedSimulation?.frontend_state?.historical_start)}`}/>
                        <TitleValueRow title={`End Date`}
                                       value={`${formatDateValue(selectedSimulation?.frontend_state?.historical_end)}`}/>
                    </Box>

                    <Box sx={{py: 1, borderTop: "1px solid #D1D5DB"}}>
                        <TitleValueRow bold title={`Simulated Date Range`}
                                       value={""}/>
                        <TitleValueRow title={`Steps`} value={selectedSimulation.metadata?.steps}/>
                        <TitleValueRow title={`Step size`} value={selectedSimulation.frontend_state?.granularitySize}/>
                        <TitleValueRow title={`Range`} value={`${formattedSimulatedRange}`}/>
                        <TitleValueRow title={`Start Date`}
                                       value={`${formatDateValue(selectedSimulation?.frontend_state?.simulation_start)}`}/>
                        <TitleValueRow title={`End Date`}
                                       value={`${formatDateValue(selectedSimulation?.frontend_state?.simulation_end)}`}/>
                    </Box>

                </PanelBox>}

                <ResponsiveBox sx={{display: "flex"}}>
                    <PanelBox sx={{p: 2, marginBottom: 2, flex: 1}}>
                        <HeaderComponent title="More details"/>


                        <Box sx={{py: 1, borderTop: "1px solid #D1D5DB"}}>
                            {selectedSimulation?.started_at &&
                                <TitleValueRow title={`Start Time`}
                                               value={formatDateValue(selectedSimulation?.started_at, true)}/>}
                            {selectedSimulation?.finished_at &&
                                <TitleValueRow title={`End Time`}
                                               value={formatDateValue(selectedSimulation?.finished_at, true)}/>}
                            {selectedSimulation?.started_at && selectedSimulation?.finished_at &&
                                <TitleValueRow title={`Duration`} value={runtimeDuration}/>}

                            {estimatedDuration &&
                                <TitleValueRow title={`Estimated duration`} value={estimatedDuration}/>}
                            {estimatedCost && <TitleValueRow title={`Estimated cost`} value={estimatedCost}/>}
                        </Box>

                    </PanelBox>
                </ResponsiveBox>
            </ResponsiveBox>

        </ResponsiveBox>

    </Box>
}
