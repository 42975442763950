import React from "react";
import {Box, CircularProgress, ToggleButton, ToggleButtonGroup} from "@mui/material";
import styled from "@emotion/styled";


import {Bar, Doughnut, Line} from 'react-chartjs-2';

import {
    CategoryScale,
    Chart,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Filler,
    Legend, BarElement, ArcElement,
} from "chart.js";

import annotationPlugin from 'chartjs-plugin-annotation';
import {useAppState} from "../../state/AppStateProvider";
import ErrorBoundary from "../error-handling/ErrorBoundary";
import {graphColorsArray} from "../../views/simulation/create-simulation/setup-simulation/constants";

Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, PointElement, LineElement, Tooltip, Filler, Legend, annotationPlugin);

const LoadingOverlay = () => {
    return <Box id="loading-overlay" style={{position: "absolute"}}>
        <CircularProgress color="success"/>
    </Box>;
}

const PanelContainer = styled(`div`)({
    height: "100%",
    position: "relative"
})


export const GraphToggleButtonGroup = styled(ToggleButtonGroup)`
    background-color: #fff;
    border-radius: 12px;
    height: 45px;
`;
export const GraphToggleButton = styled(ToggleButton)`
    border-radius: 12px !important;
    text-transform: capitalize;
    font-size: 14px;
    color: #000;
    border: none;
    margin: 4px;
`;


//TODO: TYPE
export type AlmanakChartData = any;
export type AlmanakChartOptions = any;
export type GraphProps = {
    data: AlmanakChartData,
    options: AlmanakChartOptions,
    type?: "line" | "bar" | "doughnut",
    isLoading?: boolean;
    height?: string;
    error?: string;
    hideYAxis?: boolean;
}

const generateScales = (datasets: any, hideYAxis?: boolean) => {
    const scales = {
        x: {
            grid: {
                display: false
            },
        }
    }
    datasets.forEach((dataset: any, index: number) => {
        const scaleId = `y${dataset.data[0].y}`;
        // @ts-ignore
        scales[scaleId] = {
            type: 'linear',
            display: hideYAxis ? false : true,
            color: graphColorsArray[index],
            grid: {
                display: false
            },
        };
    });
    return scales;
};

const AlmanakChart = (props: GraphProps) => {

    const {theme} = useAppState();
    const themedOptions = {
        ...props.options,
        animation: false,
        parsing: false,
        responsive: true,
        plugins: {
            ...props.options.plugins,
        },
        scales: generateScales(props.data.datasets, props.hideYAxis)
    }

    const normalizedData = {
        ...props.data,
        datasets: props.data.datasets.map((dataset: any, index: number) => {
            return ({
                ...dataset,
                yAxisID: `y${dataset.data[0].y}`
            })
        })
    };


    const data = {
        labels: normalizedData.labels,
        datasets: normalizedData.datasets,
    };

    return <div>
        <ErrorBoundary>
            {props.error && <Box>{props.error}</Box>}
            <PanelContainer
                style={{height: props.height ?? "300px"}}>

                {props.isLoading && <LoadingOverlay/>}

                {props.type === "bar" ?
                    <Bar options={themedOptions} data={props.data}/> :
                    props.type === "doughnut" ?
                        <Doughnut options={props.options} data={props.data}/> :

                        <Line
                            data={data}
                            options={themedOptions}
                        />
                }
            </PanelContainer>
        </ErrorBoundary>
    </div>
}

export default AlmanakChart
