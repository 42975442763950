import {TokenDayData} from "../state/types";
import {MovingAverageData, SimulationStrategyResults, TokenData} from "../components/almanak-chart/graphTypes";

export const formatPubKey = (pubKey?: string) => {
    if (!pubKey) return;
    return pubKey.slice(0, 6) + "..." + pubKey.slice(-4);
}

const convertCamelCaseToTitleCase = (input: string): string => {
    const a = input.replace("USD", " $").replace(/([A-Z])/g, " $1");
    return a.charAt(0).toUpperCase() + a.slice(1);
}
export const remapTokenDataToInfoList = (token: TokenData) => {

    const keys = Object.keys(token);
    return keys.map(key => {
        // @ts-ignore
        const value = Number(token[key]) ? Number(token[key]).toLocaleString('en-US') : token[key];
        return {title: convertCamelCaseToTitleCase(key), value}
    })
}

// about EMA
// for X-day moving average: [2 ÷ (X + 1)]
// EMA = Closing price * multiplier + EMA (previous day) x (1-multiplier)

export const getMovingAverageFromData = (tokenGraphData: TokenDayData[], maPeriod: number, simulationRange: number): MovingAverageData[] => {

    const smaGraphData: MovingAverageData[] = [];


    let smaValue = 0;
    let smaCount = 0;
    const relevantDataForMovingAverage = tokenGraphData.slice(-(simulationRange + maPeriod));
    relevantDataForMovingAverage.forEach((tokenData, index) => {
        smaValue += Number(tokenData.close);
        if (index > maPeriod) {
            smaValue -= Number(relevantDataForMovingAverage[index - maPeriod].close)
        } else {
            smaCount++;
        }
        smaGraphData.push({value: smaValue / smaCount, date: tokenData.date});
    });

    return smaGraphData.splice(-simulationRange)

};

export type TradeData = {
    position: string,
    date: number,
    price: number
}

export const getExecutedTradesDataFromMovingAverages = (shortMovingAverageData: MovingAverageData[], longMovingAverageData: MovingAverageData[], tokenGraphData: TokenDayData[]): TradeData[] => {

    let positions: TradeData[] = [];
    const adaptedLongMovingAverageData = longMovingAverageData.slice(shortMovingAverageData.length - longMovingAverageData.length)

    let isShortMovingAverageLarger = Number(shortMovingAverageData[0].value) > Number(adaptedLongMovingAverageData[0].value);
    let isLongMovingAverageLarger = Number(adaptedLongMovingAverageData[0].value) > Number(shortMovingAverageData[0].value);


    // initialize first buy position
    // const price = Number(tokenGraphData.find(token => token.date === adaptedLongMovingAverageData[0].date)?.priceUSD)!
    // positions.push({position: "buy", date: adaptedLongMovingAverageData[0].date, price})

    adaptedLongMovingAverageData.forEach((ma, index) => {

            const isCurrentShortLarger = Number(shortMovingAverageData[index].value) > Number(adaptedLongMovingAverageData[index].value);
            const isCurrentLongLarger = Number(longMovingAverageData[index].value) > Number(shortMovingAverageData[index].value);

            const didCrossoverHappen = (isShortMovingAverageLarger && isCurrentLongLarger) || (isLongMovingAverageLarger && isCurrentShortLarger);

            if (didCrossoverHappen) {

                try {
                    // if (adaptedLongMovingAverageData[index].value && shortMovingAverageData[index].value && adaptedLongMovingAverageData[index].date === shortMovingAverageData[index].date) {
                    if (isCurrentShortLarger) {

                        //get actual price of the token
                        const price = Number(tokenGraphData.find(token => {
                            return token.date === adaptedLongMovingAverageData[index].date
                        })?.priceUSD)

                        positions.push({position: "buy", date: adaptedLongMovingAverageData[index].date, price})
                    }

                    if (isCurrentLongLarger) {
                        const price = Number(tokenGraphData.find(token => token.date === adaptedLongMovingAverageData[index].date)?.priceUSD)!
                        positions.push({position: "sell", date: adaptedLongMovingAverageData[index].date, price})
                        isShortMovingAverageLarger = false;

                    }
                    // }

                    isShortMovingAverageLarger = isCurrentShortLarger;
                    isLongMovingAverageLarger = isCurrentLongLarger;


                } catch (err: any) {
                    //console.log("error!", err);
                }
            }
        }
    )

    return positions;
}

function calculateStrategySimulationResults(simulationGraphData: TokenDayData[], executedTrades: TradeData[], initialCapital: number, orderSize: number): SimulationStrategyResults {
    let availableCapital = initialCapital;
    let unitsBought = 0;
    executedTrades.forEach((trade, index) => {
        if (trade.position === "buy") {
            unitsBought = initialCapital * (orderSize / 100) / Number(trade.price);
            availableCapital -= Number(trade.price) * unitsBought
        }
        if (index !== 0 && trade.position === "sell") {
            availableCapital += Number(trade.price) * unitsBought;
            unitsBought = 0;
        }
    });

    const holdProfit = Number(simulationGraphData[simulationGraphData.length - 1].priceUSD) - Number(simulationGraphData[0].priceUSD);
    const profitInPercent = ((availableCapital - initialCapital) / initialCapital) * 100


    return {finalCapital: availableCapital, totalTrades: executedTrades.length, holdProfit, profitInPercent}
}

export {calculateStrategySimulationResults}
