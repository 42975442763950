import Chip from "@mui/material/Chip";
import * as React from "react";

const WalletStatus = ({wallet}: any) => {

    return wallet.is_active ? <Chip color={"success"} label={"Active"}/> :
        wallet.tx_hash ? <Chip color={"info"} label={"Submitted"}/> :
            <Chip color={"warning"} label={"Pending"}/>

}

export default WalletStatus;
