import Box from "@mui/material/Box";
import {InputAdornment, Paper, TableContainer, TextField} from "@mui/material";
import {SearchOutlined} from "@mui/icons-material";
import * as React from "react";
import Divider from "@mui/material/Divider";
import {ChartTitle} from "./agent-results/TimeSeriesResultsChart";
import {useAppState} from "../../../state/AppStateProvider";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import {formatMonetaryValue} from "../create-simulation/PoolDetails";

const columns: GridColDef[] = [
    {
        field: 'id', headerName: 'Agent ID', flex: 1, disableColumnMenu: true,
    },
    {
        field: 'alias', headerName: 'Alias', flex: 1, disableColumnMenu: true,
    },
    {
        field: 'type', headerName: 'Type', flex: 1, disableColumnMenu: true,
    },
    {
        field: 'class', headerName: 'Strategy', flex: 1, disableColumnMenu: true,
    },
    {
        field: 'ROI',
        headerName: 'ROI',
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params) => <span className={"text-bold"}>{params.value ? `${params.value} %` : " - "}</span>
    },
    {
        field: 'PNL',
        headerName: 'PNL',
        flex: 1,
        disableColumnMenu: true,
        renderCell: (params) => <span className={"text-bold"} style={params.value > 0 ? {color: "#356C58"} : {color: "#D32F2F"}}>{formatMonetaryValue(params.value, {currency: "USD"})}</span>
    },

];

export const AgentsResultsListTable = ({isLoading}: { isLoading?: boolean }) => {

    const {selectedSimulation} = useAppState();
    const navigate = useNavigate();
    const agentsObj = selectedSimulation?.aggregated_metrics?.agents ?? {};

    const agentsArray = Object.keys(agentsObj).map((key) => {
        return {
            address: key,
            ...agentsObj[key],
            ...selectedSimulation?.agent_configs?.[0]?.agents?.find((agent: any) => agent.address === key),
            PNL: agentsObj[key]?.computed_statistic?.expectedPL?.PNL,
            ROI: agentsObj[key]?.computed_statistic?.expectedPL?.ROI,
        };
    });

    return <Box sx={{my: 2}}>
        <ChartTitle sx={{marginBottom: "16px"}}>
            Simulated Agents
        </ChartTitle>
        <TableContainer component={Paper}>

            {/*<Box sx={{m: 3, display: "flex", justifyContent: "space-between"}}>*/}
            {/*    <TextField*/}
            {/*        label="Search for Agents"*/}
            {/*        size="small"*/}
            {/*        InputProps={{*/}
            {/*            startAdornment: (*/}
            {/*                <InputAdornment position="start">*/}
            {/*                    <SearchOutlined/>*/}
            {/*                </InputAdornment>*/}
            {/*            ),*/}
            {/*        }}*/}
            {/*        variant="outlined"*/}
            {/*    />*/}

            {/*</Box>*/}

            <TableContainer component={Paper}>

                {agentsArray && <DataGrid
                    getRowId={(row) => row.address}
                    onRowClick={(item) => navigate(`/simulation/${selectedSimulation?.id}/results/agent/${item.row.address}`)}
                    rows={agentsArray}
                    columns={columns}
                    loading={isLoading}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10},
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                />}
            </TableContainer>
            <Divider/>
        </TableContainer>
    </Box>
}
