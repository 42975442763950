import {useNavigate, useParams} from "react-router-dom";
import {Button, styled} from "@mui/material";
import Box from "@mui/material/Box";
import AlmanakChart, {
    AlmanakChartData,
    GraphToggleButton,
    GraphToggleButtonGroup
} from "../../../components/almanak-chart/AlmanakChart";
import {useAppState} from "../../../state/AppStateProvider";
import {useEffect, useState} from "react";
import {PageLoadingView} from "./PageLoadingView";
import {fetchPoolForDetailsByAddress, fetchTokenDayDatasById} from "../../../api/uniswap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {fetchMintersAndTraders} from "../../../api/hasura";
import {
    feesChartMock,
    graphRangeOptions,
    poolDetailsGraphTypes,
    tvlChartMock,
    volumeChartMock
} from "../../../constants";
import {areaChartOptions} from "../../../components/almanak-chart/graph-constants";
import {TokenPairHeader} from "../TokenPairHeader";
import {LabelValueChangeCard, EValueType} from "../LabelValueChangeCard";
import {DateTimeFormatOptions} from "luxon";
import {PageContentContainer} from "../../styled/styled";
import {Dayjs} from "dayjs";
import {PanelBox} from "./styled";

export const formatMonetaryValue = (amount?: number | string, options?: { compact?: boolean, currency?: string, fractionDigits?: number }) => {
    const {compact, currency, fractionDigits} = options ?? {};
    if (amount === undefined) return "N/A"
    const numberFormat = new Intl.NumberFormat('en-US', {
        style: currency ? 'currency' : "decimal",
        currency,
        notation: compact ? "compact" : "standard",
        minimumFractionDigits: fractionDigits ?? 2
    });

    return numberFormat.format(Number(amount))
}

export const formatDateValue = (date?: Date | Dayjs | string, showTime?: boolean) => {
    if (!date) return "-";

    const dateOptions: DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    const dateTimeOptions: DateTimeFormatOptions = {
        ...dateOptions,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
    }

    return new Date(date.toString()).toLocaleDateString('en-us', showTime ? dateTimeOptions : dateOptions)
}


const cardsListItems = [
    {title: "TVL", key: "totalValueLockedUSD", type: EValueType.monetary},
    {title: "Volume (24h)", key: "volumeUSD", type: EValueType.monetary},
    // {title: "APR (24h)", key: "apr", type: EValueType.number},
    {title: "Fees (24h)", key: "feesUSD", type: EValueType.monetary},
    {title: "Traders", key: "traders", type: EValueType.number},
    {title: "LP Providers", key: "minters", type: EValueType.number},
]

const PoolDetails = () => {

    const {
        setSelectedGraphRange,
        selectedGraphRange,
        selectedTokenGraphData,
        setSnackBar,
        theme
    } = useAppState();

    let navigate = useNavigate();
    const {id} = useParams();

    //TODO: type
    const [poolData, setPoolData] = useState<any>(null);

    const [selectedChartType, setSelectedChartType] = useState("tvl");

    const [chartData, setChartData] = useState<AlmanakChartData>();

    const updateChart = (data: AlmanakChartData, graphRange: number) => {
        const selectedChartData = data?.slice(0, graphRange);
        const chartLabels = selectedChartData?.map((d: any) => new Date(d.date * 1000).toDateString());
        const dataForChart = {
            labels: chartLabels,
            datasets: [
                {
                    fill: true,
                    title: 'TVL',
                    data: selectedChartData?.map((d: any) => d.volume),
                    borderColor: theme === "dark" ? "#FFF" : "#000",
                    backgroundColor: theme === "dark" ? "#C2C2C24F" : "#C2C2C2"

                }
            ],
        }
        setChartData(dataForChart)
    };
    useEffect(() => {
        const fetchPoolData = async (address: string) => {
            try {
                const data = await fetchPoolForDetailsByAddress(address);
                const poolData = data.data.data.pool;
                setPoolData((prevState: any) => ({...prevState, ...poolData}));
                updateChart(selectedTokenGraphData, selectedGraphRange);
            } catch (e: any) {
                setSnackBar({open: true, message: e.message, severity: "error"});
            }
        };

        const fetchPoolChartData = async (address: string) => {
            try {

                const poolChartData = await fetchTokenDayDatasById(address);
            } catch (e: any) {
                setSnackBar({open: true, message: e.message, severity: "error"});
            }
        }
        const fetchAdditionalPoolDetails = async (address: string) => {
            try {
                const tradersMintersData = await fetchMintersAndTraders(address)
                const minters = tradersMintersData.data.data.fetchNumberOfLpProvidersPerUniswapV3Pool.number_of_minters;
                const traders = tradersMintersData.data.data.fetchNumberOfTradersPerUniswapV3Pool.number_of_traders;

                setPoolData((prevState: any) => ({...prevState, traders, minters}));

            } catch (e: any) {
                setSnackBar({open: true, message: e.message, severity: "error"});
            }

        };
        if (id) {
            fetchPoolData(id);
            fetchPoolChartData(id);
            fetchAdditionalPoolDetails(id);
        }
    }, [id])

    if (!poolData && id) {
        return <PageLoadingView title="Loading Pool details..." subTitle={id}/>
    }

    const onRangeChange = async (e: any, value: string) => {
        setSelectedChartType(value);
        if (value === "fees") {
            updateChart(feesChartMock, selectedGraphRange);
        } else if (value === "volume") {
            updateChart(volumeChartMock, selectedGraphRange);
        } else if (value === "tvl") {
            updateChart(tvlChartMock, selectedGraphRange);
        }
    }

    const RenderGraphControls = () => {
        return <>
            <Box sx={{display: "flex", justifyContent: "space-between", width: "100%", py: 2, px: 3}}>
                <GraphToggleButtonGroup
                    value={selectedChartType}
                    exclusive
                    onChange={onRangeChange}
                    aria-label="selected range"
                >
                    {poolDetailsGraphTypes.map(range => <GraphToggleButton
                        selected={range.type === selectedChartType}
                        key={range.value}
                        value={range.value}
                        aria-label={range.title}>
                        {range.title}
                    </GraphToggleButton>)
                    }
                </GraphToggleButtonGroup>
                <GraphToggleButtonGroup
                    value={selectedGraphRange}
                    exclusive
                    onChange={(e: any, value: any) => {
                        updateChart(selectedTokenGraphData, value)
                        setSelectedGraphRange(value)
                    }}
                    aria-label="selected range"
                >
                    {graphRangeOptions.map(range => <GraphToggleButton
                        selected={range.value === selectedGraphRange}
                        key={range.value}
                        value={range.value}
                        aria-label={range.title}>
                        {range.title}
                    </GraphToggleButton>)
                    }
                </GraphToggleButtonGroup>
            </Box>
        </>
    }

    return (
        <PageContentContainer sx={{gap: 2, my: 2}}>
            <Box sx={{display: "flex", flexDirection: "row", gap: 2, justifyContent: "space-between"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <div>
                        <Button onClick={() => navigate("/simulation/new/create")}
                                variant="text"
                                sx={{padding: "0"}}
                                startIcon={<ArrowBackIcon/>}>
                            Back
                        </Button>
                    </div>
                    {poolData && <TokenPairHeader
                        network={"Ethereum"}
                        dexProtocol={"Uniswap"}
                        token1={poolData.token0.symbol}
                        token2={poolData.token1.symbol}
                        feeTier={poolData.feeTier}
                        contractAddress={poolData.id}/>
                    }
                </Box>


            </Box>

            <Box sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2
            }}>

                {poolData && cardsListItems.map(card =>
                    <LabelValueChangeCard key={card.key} title={card.title} value={poolData[card.key]}
                                          type={card.type} isLoading={!poolData[card.key]}/>
                )}
            </Box>


            <PanelBox>
                {RenderGraphControls()}
                <AlmanakChart
                    height={"400px"}
                    data={chartData}
                    options={areaChartOptions}
                />
            </PanelBox>

            {/*<FloatingControls primary={{title: "Start a new Simulation", onClick: createNewSimulation, isLoading}}/>*/}


        </PageContentContainer>

    );
}

export default PoolDetails;
