import Box from "@mui/material/Box";
import {OpenInNew} from "@mui/icons-material";
import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {PageContentContainer} from "../styled/styled";
import Divider from "@mui/material/Divider";
import {createGroupSimulation} from "../../api/hasura";
import {defaultGroupSimulation, ESimulationStatus} from "../../state/types";
import {useAppState} from "../../state/AppStateProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import {PanelBox} from "../simulation/create-simulation/styled";
import {lp3AgentsTemplateFrontendState} from "../../constants";
import {openAlmanakDocs} from "../../components/layout/Sidebar";

export type SimulationTemplate = {
    id: string;
    title: string;
    frontendState: any;
    description: string;
    targetAudience: string;
    isCreating?: boolean;
    metadata: any;

}
export const availableSimulationTemplates: SimulationTemplate[] = [
    {
        //random uuid
        id: "4123ag-1234-1234-1234-1234",
        title: "Uni LP v3 Strategy Template (4 agents)",
        frontendState: lp3AgentsTemplateFrontendState,
        description: "Simulate Uni LP v3 strategies with agents and define your strategy. This template consists of 1 Trader Agent, 1 Trader Agent with trading model, 1 LP Provider Agent and 1 Arbitrageur agent. The strategy simulates over 3 different market scenarios and 5 different price trajectories for each of the strategy. It simulates in an hourly intervals and simulates 2 weeks in the future. It's originaly simulated over ETH-USDT pair.",
        targetAudience: "LP Providers, Traders, Arbitrageurs",
        metadata: {
            "block": 20207050,
            "steps": 720,
            "tokens": [
                {
                    "name": "USDT",
                    "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
                    "isNative": false
                },
                {
                    "name": "WETH",
                    "address": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
                    "isNative": false
                },
                {
                    "name": "WBTC",
                    "address": "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
                    "isNative": false
                },
                {
                    "name": "ETH",
                    "address": "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
                    "isNative": true,
                    "nativeLabel": "Uniswap"
                }
            ]
        }
    },
    // {
    //     id: 2,
    //     title: "Arrakis Vault Manager Strategy template",
    //     frontendState: arakisManagerTemplateFrontendState,
    //     description: "Arrakis vaults are the biggest holders of uniswap v3 liquidity. With this simulation template, actively manage liquidity on uniswap v3 through the Arrakis vault manager. A Manager/Users can deposit their liquidity into the Arrakis vault, and the Arrakis vault manager can rebalance the position automatically. The vault manager may or may not charge a fee for this service. This template consists of 1 Arakis Vault Manager Agent, 1 Trader Agent, 1 Trader Agent with trading model, and 1 Arbitrageur agent. The strategy simulates over 3 different market scenarios and 5 different price trajectories for each of the strategy. It simulates in an hourly intervals and simulates 2 weeks in the future. It's originaly simulated over ETH-USDT pair.",
    //     targetAudience: "LP Managers"
    // },

]

export const CreateSimulation = () => {
    const [isLoading, setIsLoading] = useState(false);

    const {setSelectedSimulation, user, setSnackBar, setSimulatorPriceTrajectoryHashes} = useAppState();

    const [creatingFromTemplateId, setCreatingFromTemplateId] = useState<string | null>(null);
    const [isDocsTokenGenerating, setIsDocsTokenGenerating] = useState(false);

    const navigate = useNavigate();


    const openDocs = async () => {
        setIsDocsTokenGenerating(true);
        try {
            const subUrl = '/multiverse/getting-started'
            await openAlmanakDocs(subUrl);
        } catch (err: any) {
            setSnackBar({open: true, message: "Could not open docs, please contact support", severity: "error"});
        } finally {
            setIsDocsTokenGenerating(false);
        }
    }

    const createNewSimulation = async () => {
        setIsLoading(true);
        const createdGroupSimulation = await createGroupSimulation(defaultGroupSimulation, user!);

        if (createdGroupSimulation.data.data?.insert_group_simulations_one?.id) {
            const simulationData = createdGroupSimulation.data.data.insert_group_simulations_one;
            setSelectedSimulation({
                ...simulationData,
                frontend_state: {...simulationData.frontend_state, activeStep: 0}
            })
            navigate(`/simulation/${createdGroupSimulation.data.data.insert_group_simulations_one.id}`)
        } else if (createdGroupSimulation.data.errors) {
            //console.log("ERROR!", createdGroupSimulation.data.errors);
            setSnackBar({open: true, severity: "error", message: `Could not create simulation: ${createdGroupSimulation.data.errors[0].message}`})
        }
        setIsLoading(false);
    };

    const onUseTemplate = async (template: SimulationTemplate) => {

        setCreatingFromTemplateId(template.id);
        try {
            template.isCreating = true;

            const createdSimulation = await createGroupSimulation({
                ...defaultGroupSimulation,
                frontend_state: template.frontendState
            }, user!);
            if (createdSimulation.data.data?.insert_group_simulations_one?.id) {
                const simulationData = createdSimulation.data.data.insert_group_simulations_one;
                setSelectedSimulation({
                    ...simulationData,
                    frontend_state: {...simulationData.frontend_state, activeStep: 0}
                })
                navigate(`/simulation/${createdSimulation.data.data.insert_group_simulations_one.id}`)
                setSnackBar({open: true, severity: "success", message: "Simulation from template created"})
            } else if (createdSimulation.data.errors) {
                setSnackBar({open: true, severity: "error", message: `Could not create simulation from template: ${createdSimulation.data.errors[0].message}`})
                return;
            }
        } catch (err: any) {
            setSnackBar({
                open: true,
                severity: "error",
                message: `Could not create simulation from template: ${err.message}`
            })

        } finally {
            setCreatingFromTemplateId(null);
        }

        setSimulatorPriceTrajectoryHashes(template.frontendState.priceConfigs)

        setSelectedSimulation({
            ...template,
            description: "Simulation from template",
            agent_configs: template.frontendState.agentConfigs,
            frontend_state: template.frontendState,
            price_configs: template.frontendState.priceConfigs,
            status: ESimulationStatus.Pending
        })
    }
    return <PageContentContainer>
        {/*<Box>*/}
        {/*    <Button variant="text"*/}
        {/*            sx={{px: 0}}*/}
        {/*            onClick={() => navigate(-1)}*/}
        {/*            startIcon={<ArrowBackIosNew/>}>Back</Button>*/}
        {/*</Box>*/}

        <Box sx={{
            marginTop: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap"
        }}>
            <Box sx={{my: 2, display: "flex", gap: 1, flexDirection: "column", maxWidth: "660px"}}>
                <span style={{
                    fontSize: "18px",
                    fontWeight: "700"
                }}>Simulate Uniswap LP Strategies with our Agent-based Model</span>
                <span>
                    Multiverse is a generalized platform for running agent-based simulations on blockchains. You may customize the environment, the agents, and optimization schema of your simulation.
                </span>
                <Box>
                    <LoadingButton loading={isDocsTokenGenerating} onClick={openDocs} startIcon={<OpenInNew/>} size="small" variant="text">View Guide </LoadingButton>
                </Box>
            </Box>
            <Box sx={{my: 2}}>
                {/*TODO: remove the on use template once new params introduced*/}
                <LoadingButton loading={isLoading} sx={{textTransform: "none"}} size="large" variant="contained"
                               onClick={createNewSimulation}> Create from scratch</LoadingButton>
            </Box>
        </Box>


        <Divider>OR</Divider>

        <Box sx={{my: 2, display: "flex", flexDirection: "column", gap: 2}}>

            <Box>
                <div style={{fontSize: "16px", fontWeight: "bold"}}>Browse templates</div>
                <span style={{fontSize: "14px"}}>To get started quickly and use preconfigured settings, choose from a template below.</span>
            </Box>

            <Box sx={{gap: 2, display: "flex", flexDirection: "column"}}>
                {availableSimulationTemplates?.map(template =>
                    <PanelBox key={template.id} sx={{p: 3}}>
                        <Box sx={{display: "flex", justifyContent: "space-between", marginBottom: 2}}>
                            <Box sx={{display: "flex", alignItems: "center", gap: 1,}}>
                                <div style={{fontSize: "16px", fontWeight: "bold"}}>{template.title}</div>
                            </Box>
                            <LoadingButton disabled={!!creatingFromTemplateId}
                                           loading={template.id === creatingFromTemplateId}
                                           onClick={() => onUseTemplate(template)}
                                           variant="outlined">Try it out</LoadingButton>
                        </Box>
                        <Divider/>
                        <Box sx={{fontSize: "14px"}}>
                            <Box sx={{py: 2}}>{template.description}</Box>
                            <Box>
                                <span style={{fontWeight: "bold"}}>For: <span
                                    style={{fontWeight: "normal"}}>{template.targetAudience}</span></span>
                            </Box>
                        </Box>
                    </PanelBox>)}
            </Box>

            <span style={{fontSize: "14px"}}>More templates coming soon... </span>
        </Box>
    </PageContentContainer>

}
