import Box from "@mui/material/Box";
import {Alert, Button, TextField} from "@mui/material";
import {PanelBox} from "../simulation/create-simulation/styled";
import * as React from "react";
import {useEffect, useState} from "react";
import {LDKeyUserApiKeyPanel, useAppState} from "../../state/AppStateProvider";
import {PageContentContainer} from "../styled/styled";
import {getApiKey, getUserCreditBalance, resetApiKey, updateUser} from "../../api/hasura";
import LoadingButton from "@mui/lab/LoadingButton";
import {TitleValueColumn} from "../../modals/AddWalletModal";
import {formatDateValue, formatMonetaryValue} from "../simulation/create-simulation/PoolDetails";
import useSWR from "swr";
import {Link} from "react-router-dom";

export type ApiKey = {
    jwt: string;
    created_at: string;
    updated_at: string;
    user_id: string;
    active: boolean;
}

export const UserProfile = () => {

    const {
        user,
        setUser,
        setSnackBar,
        featureFlags
    } = useAppState();

    const {
        data: creditBalance,
    } = useSWR(`user-credits`, getUserCreditBalance);


    const isApiKeyFeatureEnabled = featureFlags?.[LDKeyUserApiKeyPanel];
    const [apiKey, setApiKey] = useState<ApiKey | null>(null)
    const [isRegeneratingKey, setIsRegeneratingKey] = useState(false);

    useEffect(() => {
        if (isApiKeyFeatureEnabled) {
            fetchApiKey();
        }
    }, [isApiKeyFeatureEnabled])

    const fetchApiKey = async () => {
        const apiKey = await getApiKey();
        setApiKey(apiKey);
    };

    const regenerateKey = async () => {
        setIsRegeneratingKey(true);
        const newKey = await resetApiKey();
        setApiKey(newKey);
        setSnackBar({open: true, message: "API key regenerated", severity: "success"});
        setIsRegeneratingKey(false);
    };


    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({
        name: user?.name ?? "",
        notification_email: user?.notification_email ?? ""
    });
    const [values, setValues] = useState(initialValues);

    const saveProfile = async () => {
        if (!user?.id) {
            console.error("Something went wrong - User id is not defined.");
            return;
        }

        try {
            setIsLoading(true);
            await updateUser(user.id, values);
            setInitialValues(values);
            setUser({...user, ...values})
            setSnackBar({open: true, message: "User updated", severity: "success"});
        } catch (err: any) {
            console.error(err);
            setSnackBar({open: true, message: err.message, severity: "error"});
        } finally {
            setIsLoading(false);
        }


    }

    const onChange = (e: any) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const copyApiKey = async () => {
        if (!apiKey?.jwt) {
            setSnackBar({open: true, message: "No API key found", severity: "error"});
            return;
        }
        await navigator.clipboard.writeText(apiKey?.jwt)
        setSnackBar({open: true, message: "Copied to clipboard"})
    }

    return <PageContentContainer>
        <Box sx={{my: 2, display: "flex", justifyContent: "space-between"}}>
                <span style={{
                    fontSize: "18px",
                    fontWeight: "700"
                }}>User Profile</span>

        </Box>
        <PanelBox sx={{p: 4, maxWidth: "740px"}}>
            <Box>
                <span style={{fontSize: "16px", fontWeight: "bold"}}>Personal details</span>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column", py: 2, gap: 1}}>
                <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "8px 32px"}}>
                    {user?.email && <TitleValueColumn title={"Login Email"} value={user?.email}/>}
                    {user?.email && <TitleValueColumn title={"Wallet Address"} value={user?.publicKey}/>}

                </Box>

                <Box sx={{display: "flex", my: 2, flexDirection: "row", flexWrap: "wrap", gap: 2}}>
                    <TextField name="name" onChange={onChange} sx={{maxWidth: "320px"}} fullWidth
                               label="Full name"
                               placeholder="Full name"
                               value={values.name}/>
                    <TextField name="notification_email" onChange={onChange} sx={{maxWidth: "320px"}} fullWidth
                               label="Notification e-mail" placeholder="Notifications e-mail"
                               value={values?.notification_email}/>
                </Box>
                <Box sx={{display: "flex", justifyContent: "flex-end", maxWidth: "655px"}}>
                    <LoadingButton
                        disabled={JSON.stringify(values) === JSON.stringify(initialValues)}
                        size="large"
                        loading={isLoading}
                        variant={"contained"}
                        onClick={saveProfile}
                    >
                        Save changes</LoadingButton>
                </Box>
            </Box>
        </PanelBox>

        <PanelBox sx={{my:4, p: 4, maxWidth: "740px"}}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                flexWrap: "wrap",
                gap: "8px 32px",
                maxWidth: "655px"
            }}>

                <Box>
                    <span style={{fontSize: "16px", fontWeight: "bold"}}>Multiverse Credits</span>
                </Box>
                <Box sx={{my: 1, fontSize: "16px", fontWeight: "500"}}>
                    Multiverse Credits are used to run Simulations on the Multiverse platform.
                </Box>

                <TitleValueColumn title={"Your Credits"} value={formatMonetaryValue(creditBalance)}/>
                <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Link to={'/buy-credits'}>
                        <LoadingButton
                            size="large"
                            variant={"contained"}
                        >
                            Buy Credits</LoadingButton>
                    </Link>
                </Box>

            </Box>
        </PanelBox>

        {isApiKeyFeatureEnabled && <PanelBox sx={{p: 4, maxWidth: "740px"}}>
        <Box>
                <span style={{fontSize: "16px", fontWeight: "bold"}}>API Key Management</span>
            </Box>
            {apiKey?.active ? <Box sx={{display: "flex", flexDirection: "column", py: 2, gap: 1}}>
                    <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "8px 32px"}}>
                        {user?.email && <TitleValueColumn title={"Status"} value={apiKey?.active ? "Active" : "Inactive"}/>}
                        {user?.email &&
                            <TitleValueColumn title={"Created at"} value={formatDateValue(apiKey?.created_at, true)}/>}

                    </Box>

                    <Box sx={{
                        display: "flex",
                        my: 2,
                        alignItems: "center",
                        gap: 1,
                        flexDirection: "row",
                        maxWidth: "656px"
                    }}>
                        <TextField name="api-key" fullWidth
                                   label="API Key"
                                   aria-readonly={true}
                                   placeholder="API Key"
                                   value={apiKey?.jwt ?? ""}/>
                        <Button disabled={!apiKey?.jwt} onClick={copyApiKey} aria-label="copy"> Copy </Button>
                    </Box>
                </Box>
                :
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    flexWrap: "wrap",
                    gap: "8px 32px",
                    maxWidth: "655px"
                }}>
                    <Box sx={{my: 1, fontSize: "16px", fontWeight: "500"}}>
                        API Key is required for the use of the Multiverse SDK.
                    </Box>
                    <Alert severity={"info"}>Generating an API key will invalidate the previous key. Once the key is
                        generated, please save it, as we do not store the keys for security reasons.</Alert>
                    <Box sx={{display: "flex", justifyContent: "flex-end"}}>
                        <LoadingButton
                            size="large"
                            loading={isRegeneratingKey}
                            variant={"contained"}
                            onClick={regenerateKey}
                        >
                            Generate Api Key</LoadingButton>
                    </Box>
                </Box>}
        </PanelBox>}
    </PageContentContainer>
}
