import {useMemo} from "react";
import Chip from "@mui/material/Chip";

type ChainBadgeProps = {
    chain: string
}
export const ChainBadge = ({chain}: ChainBadgeProps) => {

    const RenderChainBadge = useMemo(() => {
        return (chain: string) => {
            let color = "info"

            switch (chain.toUpperCase()) {
                case "ETHEREUM":
                    color = "success"
                    break;
                case "SEPOLIA":
                    color = "warning"
                    break;
                case "OPTIMISM":
                    color = "error"
                    break;
                case "ARBITRUM":
                    color = "info"
                    break;
                default:
                    color = "primary"
                    break;
            }

            return <Chip size={"small"} color={color as any} label={chain}/>


        }
    }, [chain]);

    return RenderChainBadge(chain);
}
