// @ts-nocheck
import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {
    Alert,
    Button,
    Checkbox,
    CircularProgress,
    FormGroup,
    MenuItem,
    Select, TextareaAutosize,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip
} from "@mui/material";
import AlmanakChart from "../../../../../components/almanak-chart/AlmanakChart";
import {
    CenteredColumnFlexBox,
    CenteredRowFlexBox,
    FlexBox,
    IconAvatar,
    MarketScenarioControlLabel,
    PanelBox,
} from "../../styled";
import {LoadingButton} from "@mui/lab";
import {LegendSquare, ResponsiveBox} from "../styled";
import {Controller, UseFormReturn} from "react-hook-form";
import {
    EGranularitySize,
    MarketScenarioOption,
    PriceTrajectoryResponse,
    SimulationMetadata,
    TokenPair
} from "../../../../../state/types";

import CustomizeIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import {useAppState} from "../../../../../state/AppStateProvider";
import {ScenarioCustomizationModal} from "../../../../../modals/ScenarioCustomizationModal";

import {
    defaultPriceScenarioChartOptions,
    EMarketScenarioColors,
    graphColorsArray,
    initialMarketScenarioOptions
} from "../constants";
import {
    fetchPriceTrajectoryResults,
    getAvailableTokenPairs,
    getBlockHeightByTime,
    getManyPriceDataV3, getManyPriceDataV3Results, updateGroupSimulation
} from "../../../../../api/hasura";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import {InfoRounded} from "@mui/icons-material";
import {latestAvailableData} from "../../../../../constants";
import {formatMetadataToDisplay, formatPriceConfigsToDisplay} from "../../../../../widgets/formatters";
import {timeframeToGranularitySize, timeframeToSeconds} from "../../../../../api/mappers";
import MultiSelectAssetPair from "./MultiSelectAssetPair";
import {findEarliestDate, findLatestDate} from "../utilities";


export type MarketScenarioContentProps = {
    formController: UseFormReturn<SimulationMetadata, any, undefined>,
    options?: {
        displayPriceSimIds?: boolean,
        displayMetadata?: boolean
    }
}

type PossibleDateTypes = "historical_start" | "historical_end" | "simulation_start" | "simulation_end";

const defaultTimeframes = [
    "1m",
    "5m",
    "10m",
    "15m",
    "30m",
    "1h",
    "4h",
    "1d"
];
const getLengthInDays = (granularitySize: EGranularitySize, supportedSteps: number) => {

    switch (granularitySize) {
        case EGranularitySize.Minute:
            return supportedSteps / 60 / 24;
        case EGranularitySize.Hour:
            return supportedSteps / 24;
        case EGranularitySize.Day:
            return supportedSteps;
        case EGranularitySize.Week:
            return supportedSteps * 7;
    }
    return 90;
};
export const getDateForXAxis = (step: number, granularity: string, simulationStart?: Dayjs) => {
    if (!simulationStart) {
        return "";
    }
    const start = dayjs(simulationStart);
    switch (granularity) {
        case "day":
            return start.add(step, granularity).format("YYYY-MM-DD")
        case "week":
            return start.add(step, granularity).format("YYYY-MM-DD")
        case "hour":
            return start.add(step, granularity).format("MMM DD HH:mm")
        case "minute":
            return start.add(step, granularity).format("YYYY-MM-DD HH:mm")
    }
};

export const MarketScenariosContent = ({formController, options}: MarketScenarioContentProps) => {

    const {control, getValues, setValue, trigger} = formController;

    const {
        selectedMarketScenarios,
        // priceScenarioChartData,
        priceScenarioChartOptions,
        granularity,
        granularitySize,
        steps,
        priceTrajectories,
        priceTrajectoryResults,
        token0,
        token1,
        start_block,
        end_block,
        simulation_start,
        historical_end,
        simulation_end,
        historical_start,
        assetPair,
        priceConfigs,
        agentConfigs,
        generatedAssetPair,
        timeframeInSeconds,
        selectedTimeframe
    } = getValues();


    const simulationStart = dayjs(simulation_start ?? new Date());
    const historicalEnd = dayjs(historical_end ?? new Date());
    const simulationEnd = dayjs(simulation_end ?? new Date());
    const historicalStart = dayjs(historical_start ?? new Date());

    const [mutualHistoricalStartDate, setMutualHistoricalStartDate] = useState<Dayjs | undefined>(undefined);
    const [mutualHistoricalEndDate, setMutualHistoricalEndDate] = useState<Dayjs | undefined>(undefined);

    const {
        selectedSimulation,
        setSelectedSimulation,
        setSnackBar,
        availableTokenPairs,
        setAvailableTokenPairs,
        simulatorPriceTrajectoryHashes,
        setSimulatorPriceTrajectoryHashes,
    } = useAppState();


    const [marketScenarioOptions, setMarketScenarioOptions] = useState(selectedMarketScenarios);

    const [isSyncingData, setIsSyncingData] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [isShowAllHistoricalDataEnabled, setShowAllHistoricalDataEnabled] = useState(false);
    const [isDisplayYAxisEnabled, setIsDisplayYAxisEnabled] = useState(true);

    const [chartData, setChartData] = useState<any>(null)
    const [chartOptions, setChartOptions] = useState<any>(priceScenarioChartOptions)

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customizeScenarioModal, setCustomizeScenarioModal] = useState<MarketScenarioOption | null>(null)

    const [availableTimeframes, setAvailableTimeframes] = useState(defaultTimeframes);

    const [selectedAssetPairs, setSelectedAssetPairs] = useState<TokenPair[] | null>(null);


    const [chartError, setChartError] = useState<String | null>(null);


    //TODO: made it 4 days as we are facing some data issues and 20 days can actually crash DB. Should be reverted later on
    const supportedSimulationLengthInDays = 360; // getLengthInDays(granularitySize, supportedSimulationLengthInSteps);

    const fetchTrajectoriesAndPopulateChart = async (priceTrajectoryResults: PriceTrajectoryResponse[], showAllHistoricalData?: boolean) => {

        const promises = priceTrajectoryResults.map((o: any) => {
            return fetchPriceTrajectoryResults(o.gcs_uri, o.asset_options?.[0]?.options ?? o.options)
        });

        await Promise.all(promises).then(res => {
            let data: any;

            //we only have 1 pair, display market scenarios colors (or no pairs, meaning old version)
            if (!selectedAssetPairs || selectedAssetPairs?.length === 1) {
                // @ts-ignore
                const color = priceTrajectoryResults?.[0].asset_options?.[0]?.options.color
                data = generateChartData(res, showAllHistoricalData, color)
            } else {
                data = generateChartData(res, showAllHistoricalData)
            }

            const options = generateChartOptions(res, showAllHistoricalData)
            const labels = generateChartLabels(res)
            setChartData((prevState: any) => ({
                labels: labels,
                datasets: [...prevState?.datasets ?? [], ...data]
            }))
            setChartOptions(options)
        })
    };
    useEffect(() => {
        const fetchAvailableTokenPairs = async () => {
            const res = await getAvailableTokenPairs();
            setAvailableTokenPairs(res)
            selectAssetPair({target: {value: assetPair}}, res)
        };
        if (!availableTokenPairs) {
            fetchAvailableTokenPairs();
        }
        if (!chartData && priceTrajectoryResults?.length > 0) {
            fetchTrajectoriesAndPopulateChart(priceTrajectoryResults, isShowAllHistoricalDataEnabled)
        }
    }, [])

    const handleCheck = (scenario: MarketScenarioOption) => {
        if (scenario.selected && marketScenarioOptions.filter(s => s.selected).length === 1) {
            setSnackBar({message: "You must select at least 1 scenario", severity: "error", open: true})
            return;
        }

        const newScenarios = marketScenarioOptions.map(s => s.type === scenario.type ? {
            ...scenario,
            selected: !scenario.selected
        } : s);
        setMarketScenarioOptions(newScenarios);
        setValue("selectedMarketScenarios", newScenarios);
    };

    type PromiseAllResponse = { data: { prices: any, volatility: any, volume: any, color: string } }

    const onDateChange = async (date: Dayjs | null, type: PossibleDateTypes) => {
        if (date) {

            setValue(type, date)
            if (type === "simulation_end") {
                const end = dayjs(date);
                const steps = end.diff(simulationStart, granularitySize);
                setValue("steps", steps)
            }

            let startBlockHeight = 0;
            if (type === "historical_start") {
                setIsSyncingData(true);
                const startBlockFromDate = await getBlockHeightByTime(date);
                startBlockHeight = Number(startBlockFromDate.data.data.getBlockHeightByTime.block_height);
                setValue("start_block", startBlockHeight)
            }

            if (type === "historical_end") {
                setIsSyncingData(true);
                setValue("historical_end", date)
                setValue("simulation_start", date)
                const endBlockFromDate = await getBlockHeightByTime(date);
                const endBlockHeight = Number(endBlockFromDate.data.data.getBlockHeightByTime.block_height);
                setValue("end_block", endBlockHeight)
                setValue("block", endBlockHeight);
            }
            setIsSyncingData(false);
        }
    }


    const startFetchingPriceSimData = async (priceSimRunId: string) => {
        return new Promise((resolve, reject) => {
            const execute = async () => {
                try {
                    const data = await getManyPriceDataV3Results(priceSimRunId);

                    let priceSimError = false;
                    if (data.error) {
                        if (data.error !== "internal: error in parsing the action log") {
                            priceSimError = true;
                            setChartError(data.error);
                            setIsGenerating(false)
                            reject()
                        }
                    } else {
                        setChartError(null);
                    }

                    if (!data.inProgress) {
                        //chart lines are generated and added here


                        const mappedConfigHashes = data.map((item: any) => item.price_config_hashkey);
                        setSimulatorPriceTrajectoryHashes((prevState: any) => [...prevState ?? [], ...mappedConfigHashes])
                        setValue("priceConfigs", [...priceConfigs ?? [], ...mappedConfigHashes])

                        await fetchTrajectoriesAndPopulateChart(data, isShowAllHistoricalDataEnabled);
                        resolve(mappedConfigHashes); // Resolve the promise when the loop is supposed to exit
                    } else if (!priceSimError) {
                        setTimeout(execute, 500); // Continue the loop if data is still in progress
                    } else {
                        reject("Price Sim failed")
                    }
                } catch (err) {
                    reject(err); // Reject the promise if there's an error
                }
            };

            execute(); // Start the loop
        });
    };


    const onTimeframeChange = (e: any) => {
        const end = dayjs(simulation_end);
        const start = dayjs(simulation_start);
        const timeframeInSeconds = timeframeToSeconds(e.target.value);
        const timeframeInGranularitySize = timeframeToGranularitySize(e.target.value);

        setValue("granularitySize", timeframeInGranularitySize)
        setValue("timeframeInSeconds", timeframeInSeconds)
        setValue("selectedTimeframe", e.target.value)
        setValue("steps", end.diff(start, timeframeInGranularitySize));
    }

    const generateChartData = (dataList: PromiseAllResponse[], showAllHistoricalData?: boolean, color?: string) => {
        if (!dataList) {
            return;
        }
        const simulatedData: any = [];


        let historical = dataList[0].data.prices.historical;
        const firstSimulatedList = dataList[0].data.prices.simulated;

        if (!showAllHistoricalData && historical.length > firstSimulatedList.length) {
            historical = historical.slice(historical.length - firstSimulatedList.length * 0.7, historical.length);
        }
        dataList.forEach(data => {
            const simulated = data.data.prices.simulated;

            // Iterate over the keys of the simulated object
            Object.keys(simulated).forEach((key: string, keyIndex: number) => {
                const simValue = simulated[key]; // Access the value using the key

                simulatedData.push({
                    data: simValue.map((d: any, i: number) => {
                        return {y: d, x: i};
                    }),
                    color: color ?? graphColorsArray[keyIndex], // Use the key to get the color
                });
            });
        });

        return simulatedData.map((item: any, i: number) => {
            return {
                data: item.data,
                fill: false,
                borderColor: item.color,
                tension: 0.4,
                cubicInterpolationMode: 'monotone',
            }
        })
    }


    const generateChartLabels = (dataList: PromiseAllResponse[]) => {
        const firstKey = Object.keys(dataList[0].data.prices.historical)[0];

        let historical = dataList[0].data.prices.historical[firstKey];
        let firstSimulatedList = dataList[0].data.prices.simulated[firstKey];

        // return ["1", "2"]
        // return [...historical, ...firstSimulatedList].map((d: any, i: number) => getDateForXAxis(i, granularitySize, simulation_start));
        return firstSimulatedList.map((d: any, i: number) => getDateForXAxis(i, granularitySize, simulation_start));

    }
    const generateChartOptions = (dataList: PromiseAllResponse[], showAllHistoricalData?: boolean) => {

        let verticalLineValue = 0;


        let historical = dataList[0].data.prices.historical;

        verticalLineValue = historical.length;


        const options = {
            ...defaultPriceScenarioChartOptions,
            plugins: {
                ...defaultPriceScenarioChartOptions.plugins,
                annotation: {
                    annotations: [
                        {
                            type: 'line',
                            borderColor: EMarketScenarioColors.rangebound,
                            borderWidth: 1,
                            display: (ctx: any) => ctx.chart.isDatasetVisible(1),
                            scaleID: 'x',
                            value: verticalLineValue
                        }
                    ]
                }
            }
        }

        return options
    }


    const generatePriceTrajectories = async () => {
        setChartError(null)
        // const isFormCorrect = await trigger();


        const {
            start_block,
            end_block,
            steps,
            priceTrajectories,
            assetPair
        } = getValues();

        if (!start_block || !end_block) {
            setSnackBar({open: true, severity: "error", message: "Please complete all required fields"})
            return;
        }

        if (dayjs(simulation_start).isAfter(dayjs(simulation_end))) {
            setSnackBar({
                open: true,
                severity: "error",
                message: "Simulation start date must be before simulation end date"
            })
            return;
        }

        const selectedPairs = assetPair?.map((pair: string) => availableTokenPairs?.find(p => p.symbol === pair));


        if (!selectedPairs) {
            setSnackBar({open: true, severity: "error", message: "Please select a valid token pair"});
            return;
        }

        setChartData(null)
        setChartOptions(null)
        setIsGenerating(true);
        setSimulatorPriceTrajectoryHashes(null);
        setValue("priceScenarioChartData", null)
        setValue("priceConfigs", [])

        // @ts-ignore
        setSelectedSimulation({
            ...selectedSimulation,
            // @ts-ignore
            frontend_state: {
              ...selectedSimulation?.frontend_state,
                priceTrajectoryResults: [],
                priceConfigs: []
            },
            price_configs: [],
            agent_configs: []
        })

        // const frontendState = {
        //     ...selectedSimulation,
        //     priceConfigs: [],
        // }
        //
        // const updatedSimulation = await updateGroupSimulation(selectedSimulation?.id!, {
        //     frontend_state: frontendState,
        //     price_configs: [],
        //     agent_configs: []
        // });
        //
        // //console.log("updatedSimulation", updatedSimulation);
        //
        // setSelectedSimulation(updatedSimulation);



        //
        // const frontendState = {
        //     ...selectedSimulation?.frontend_state,
        //     priceConfigs: []
        // }
        // const updatedSimulation = {
        //     ...selectedSimulation,
        //     price_configs: [],
        //     frontend_state: frontendState,
        // }
        //
        // //console.log("updatedSimulation", updatedSimulation);
        // // @ts-ignore
        // setSelectedSimulation(updatedSimulation);
        // // @ts-ignore
        // updateGroupSimulation(selectedSimulation?.id, {
        //     frontend_state: frontendState,
        //     price_configs: []
        // });

        try {
            const scenarios = marketScenarioOptions.filter(s => s.selected);
            // //console.log("selectedAssetPairs", selectedAssetPairs);
            // //console.log("availableTokenPairs", availableTokenPairs);
            // //console.log("selectedPairs", selectedPairs);

            const marketScenarioPromises = scenarios.map((scenario: MarketScenarioOption) => {
                // @ts-ignore
                return getManyPriceDataV3(selectedPairs, priceTrajectories, timeframeInSeconds ?? 3660, steps, start_block, end_block, scenario)
            });


            //This loop starts different market scenarios
            await Promise.all(marketScenarioPromises).then(res => {
                const priceSimPromises = res.map(id => startFetchingPriceSimData(id)); //it will generate chart data once startFetchingPriceSimData resolves

                //This loop fetches different price trajectories, they may be resolved at different times
                Promise.all(priceSimPromises)
                    .then((res) => {

                        const flattenedResponse = res.flat();


                        const newSimulation = {
                            ...selectedSimulation,
                            frontend_state: {
                                ...selectedSimulation?.frontend_state,
                                // priceTrajectoryResults: flattenedResponse,
                                priceConfigs: flattenedResponse
                            },
                            price_configs: flattenedResponse,
                            agent_configs: []
                        }

                        setSelectedSimulation(newSimulation);
                        updateGroupSimulation(selectedSimulation?.id!, newSimulation)
                        setIsGenerating(false); // Set the flag to false once all price trajectories are fetched
                    })
                    .catch(err => {
                        //console.log("An error occurred!", err);
                    });
            }).catch(err => {
                //console.log("error!", err);
            }).finally(() => {
                //console.log("finally");

            })

        } catch (err: any) {
            //console.log("ERROR!", err);
            setSnackBar({open: true, severity: "error", message: err.message})
            setIsGenerating(false);
        }

    }

    const customizeScenario = (scenario: MarketScenarioOption) => {
        setCustomizeScenarioModal(scenario)
        setIsModalOpen(true);
    }

    const onCustomizeScenario = (scenario: MarketScenarioOption) => {
        setSnackBar({open: true, severity: "success", message: "Scenario saved"})
        setIsModalOpen(false)

        const newScenarios = marketScenarioOptions.map(s => s.type === scenario.type ? scenario : s);
        setMarketScenarioOptions(newScenarios);
        setValue("selectedMarketScenarios", newScenarios);
    }


    const selectAssetPair = async (value: any, tokenPairs?: any[]) => {


        // @ts-ignore
        const realValue = value?.target?.value

        const allPairs = tokenPairs ?? availableTokenPairs;

        const selectedPairs = allPairs?.filter(tokenPair => realValue.includes(tokenPair.symbol));

        if (!selectedPairs) {
            setSnackBar({open: true, severity: "error", message: "Please select a valid token pair"})
            return;
        }


        const earliestMutualHistoricalEnd = findEarliestDate(selectedPairs, "end_date");
        const latestMutualHistoricalStartDate = findLatestDate(selectedPairs, "earliest_date");
        setSelectedAssetPairs(selectedPairs)

        setMutualHistoricalStartDate(dayjs(latestMutualHistoricalStartDate))
        setMutualHistoricalEndDate(dayjs(earliestMutualHistoricalEnd))

        const simulationEndDate = dayjs(earliestMutualHistoricalEnd).add(14, "day")

        await onDateChange(dayjs(earliestMutualHistoricalEnd).subtract(14, "day"), "historical_start")
        await onDateChange(dayjs(earliestMutualHistoricalEnd), "historical_end")
        await onDateChange(dayjs(earliestMutualHistoricalEnd), "simulation_start")
        await onDateChange(simulationEndDate, "simulation_end")

        const steps = simulationEndDate.diff(dayjs(earliestMutualHistoricalEnd), granularitySize);

        setValue("steps", steps)

        // setValue("historical_start", dayjs(earliestMutualHistoricalEnd).subtract(30, "day"))
        // setValue("historical_end", earliestMutualHistoricalEnd)
        // setValue("simulation_start", earliestMutualHistoricalEnd)
        // setValue("simulation_end", dayjs(earliestMutualHistoricalEnd).add(30, "day"))

        setSelectedAssetPairs(selectedPairs)

        setValue("assetPair", realValue)

        // setSelectedSimulation({
        //     ...selectedSimulation,
        //     price_configs: [],
        //     agent_configs: []
        // });

        //console.log("selectedPairs[0]?.timeframes", selectedPairs[0]?.timeframes);
        setAvailableTimeframes(selectedPairs[0]?.timeframes)

    }

    const toggleHistoricalDataVisibility = (e: any) => {
        setShowAllHistoricalDataEnabled(e.target.checked)
        alert("TODO")
        // populateChartWithData(promiseAllResponse, e.target.checked);
    }
    const toggleDisplayYAxis = (e: any) => {
        setIsDisplayYAxisEnabled(e.target.checked)
    }

    const RenderStep2 = () => {
        return <Box sx={{flex: 1, flexGrow: 1}}>
                        <span style={{fontSize: "16px", fontWeight: "bold"}}>Step 2: <span
                            style={{fontWeight: "normal"}}>Select Market Scenario</span></span>
            <FormGroup>
                {marketScenarioOptions.map(option =>
                    <MarketScenarioControlLabel
                        sx={{width: "100%", ".MuiFormControlLabel-label": {width: "100%"}}}
                        key={option.type}
                        control={<Checkbox
                            onChange={() => handleCheck(option)}
                            checked={option.selected}
                        />}
                        label={
                            <CenteredRowFlexBox sx={{justifyContent: "space-between", width: "100%"}}>
                                <CenteredRowFlexBox>
                                    <IconAvatar sx={{height: 36, width: 36, backgroundColor: "#000"}}>
                                        {initialMarketScenarioOptions.find(o => o.type === option.type)?.icon}
                                    </IconAvatar>
                                    <Box sx={{display: "flex", flexDirection: "column"}}>
                                                <span
                                                    style={{fontSize: "14px", fontWeight: "bold"}}>{option.title}</span>
                                        <FlexBox sx={{alignItems: "center"}}>
                                                <span
                                                    style={{fontSize: "10px"}}>Volatility: {((option.volatilityMultiplier / option.maxVolatility) * 100).toFixed(0)}%</span>
                                            {option.drift?.[0] && <>
                                            <span style={{
                                                padding: "0 4px 1px",
                                                fontSize: "10px"
                                            }}>	&#9679; </span>
                                                <span
                                                    style={{fontSize: "10px"}}>Drift: {option.drift[0]} to {option.drift[1]}</span></>}
                                        </FlexBox>
                                    </Box>
                                </CenteredRowFlexBox>
                                <CenteredRowFlexBox>
                                    <Button onClick={() => customizeScenario(option)}
                                            sx={{marginRight: "8px", fontSize: "12px"}} variant="text"
                                            startIcon={<CustomizeIcon sx={{fontSize: "14px !important"}}/>}>
                                        Customize
                                    </Button>
                                </CenteredRowFlexBox>
                            </CenteredRowFlexBox>}/>)}
            </FormGroup>
        </Box>
    }

    const RenderStep3 = () => {
        return <Box sx={{flex: 1, flexGrow: 1}}>
                        <span style={{fontSize: "16px", fontWeight: "bold"}}>Step 3: <span
                            style={{fontWeight: "normal"}}>Select number of price trajectories to run per market scenario</span></span>
            <Box sx={{py: 2}}>
                <Controller
                    name="priceTrajectories"
                    control={control}
                    key="priceTrajectories"
                    rules={
                        {required: true}
                    }
                    render={({field, fieldState: {error}}) => <Select {...field}
                                                                      onChange={e => setValue("priceTrajectories", Number(e.target.value))}
                                                                      size={"small"}
                                                                      error={error !== undefined}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>}/>
            </Box>

        </Box>
    }

    const RenderStep4 = () => {

        return <Box sx={{flex: 1, flexGrow: 1}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <Box>
                        <span style={{fontSize: "16px", fontWeight: "bold"}}>
                            Step 4: <span style={{fontWeight: "normal"}}>Select Simulation Time Horizon</span>
                        </span>
                </Box>

                <Box sx={{marginTop: 2, marginBottom: 2}}>
                    <div style={{fontSize: "14px", fontWeight: "bold"}}>Historical Date Range</div>
                    <div style={{fontSize: "14px", fontStyle: "italic"}}>
                        What Historical date range would you like to use to train the model?
                    </div>
                </Box>

                <FlexBox sx={{justifyContent: "space-between", gap: 2}}>
                    <Controller
                        name="historical_start"
                        control={control}
                        key="historicalStart"
                        rules={
                            {required: true}
                        }
                        render={({field, fieldState: {error}}) =>
                            <DatePicker {...field} value={historicalStart}
                                //TODO: replace this with mutual date range
                                        minDate={mutualHistoricalStartDate}
                                        maxDate={mutualHistoricalEndDate}
                                        onChange={(d: Dayjs | null) => onDateChange(d, "historical_start")}
                                        label={"Start Date"}
                                        sx={{width: "100%"}}
                                        slotProps={{
                                            textField: {
                                                variant: 'outlined',
                                                error: !!error,
                                                helperText: error?.message,
                                            },
                                        }}
                            />}/>


                    <Controller
                        name="historical_end"
                        control={control}
                        key="historicalEnd"
                        rules={
                            {required: true}
                        }
                        render={({field, fieldState: {error}}) =>
                            <DatePicker value={historicalEnd}
                                        sx={{width: "100%"}}
                                        minDate={mutualHistoricalStartDate?.add(5, "day")}
                                        maxDate={mutualHistoricalEndDate}
                                        onChange={(d: Dayjs | null) => onDateChange(d, "historical_end")}
                                        label={"End Date"}
                            />}/>
                </FlexBox>


                <Box sx={{my: 2}}>
                    <div style={{fontSize: "14px", fontWeight: "bold"}}>Simulated Date Range</div>
                    <div style={{fontSize: "14px", fontStyle: "italic"}}>
                        What date range would you like to simulate over?
                    </div>
                </Box>

                <FlexBox sx={{justifyContent: "space-between", gap: 2}}>
                    <DatePicker value={simulationStart}
                                disabled
                                maxDate={latestAvailableData}
                                onChange={(d: Dayjs | null) => onDateChange(d, "simulation_start")} label={"Start Date"}
                                sx={{width: "100%"}}/>
                    <Controller
                        name="simulation_end"
                        control={control}
                        key="simulationEnd"
                        rules={
                            {required: true}
                        }
                        render={({field, fieldState: {error}}) =>
                            <DatePicker maxDate={latestAvailableData?.add(supportedSimulationLengthInDays, "days")}
                                        value={simulationEnd}
                                        minDate={simulationStart?.add(1, "day")}
                                        onChange={(d: Dayjs | null) => onDateChange(d, "simulation_end")}
                                        label={"End Date"} sx={{width: "100%"}}
                                        slotProps={{
                                            textField: {
                                                variant: 'outlined',
                                                error: !!error,
                                                helperText: error?.message
                                            },
                                        }}
                            />}/>
                </FlexBox>

                <Box sx={{display: "flex", alignItems: "center", my: 1}}>
                    <div style={{fontSize: "14px", fontWeight: "bold"}}>Interval</div>
                    <Tooltip
                        title={`The step size to use for the simulation`}>
                        <InfoRounded sx={{m: 1}}/>
                    </Tooltip>
                </Box>

                {!assetPair &&
                    <Box sx={{fontSize: "14px", fontStyle: "italic"}}>Please select an asset pair first</Box>}
                <Controller
                    name="selectedTimeframe"
                    control={control}
                    key="selectedTimeframe"
                    rules={
                        {required: true}
                    }
                    render={({field, fieldState: {error}}) => <ToggleButtonGroup {...field}
                                                                                 onChange={onTimeframeChange}
                                                                                 fullWidth size="small">
                        {availableTimeframes?.map(timeframe =>
                            <ToggleButton key={timeframe} sx={{textTransform: "none"}}
                                          value={timeframe}>{timeframe}</ToggleButton>)}
                    </ToggleButtonGroup>}/>

            </LocalizationProvider>
        </Box>
    };


    const copyTrajectoryIds = async () => {
        await navigator.clipboard.writeText(formatPriceConfigsToDisplay(priceConfigs))
        setSnackBar({open: true, message: "Copied to clipboard"})
    }

    const copyMetadata = async () => {
        await navigator.clipboard.writeText(formatMetadataToDisplay(getValues()));
        setSnackBar({open: true, message: "Copied to clipboard"})
    }

    const RenderOptions = () => {
        return <>
            {options?.displayPriceSimIds && <PanelBox sx={{p: 2, m: 2, gap: 2}}>
                <Box sx={{p: 2, display: "flex", justifyContent: "space-between"}}>
                <span style={{fontSize: "16px", fontWeight: "bold"}}>
                    Generated Price Trajecotory IDs
                </span>

                    <Box>
                        <Button onClick={copyTrajectoryIds} variant={"contained"}>Copy for use in SDK</Button>
                    </Box>
                </Box>

                <Box style={{fontSize: "12px"}}>

                    <TextareaAutosize id="priceConfigIdsTextArea"
                                      style={{minHeight: "200px", minWidth: "100%", maxWidth: "100%", padding: "16px"}}
                                      value={formatPriceConfigsToDisplay(priceConfigs)}
                    />
                </Box>
            </PanelBox>}

            {options?.displayMetadata && <PanelBox sx={{p: 2, m: 2, gap: 2}}>
                <Box sx={{p: 2, display: "flex", justifyContent: "space-between"}}>
                <span style={{fontSize: "16px", fontWeight: "bold"}}>
                    Simulation Metadata
                </span>

                    <Box>
                        <Button onClick={copyMetadata} variant={"contained"}>Copy for use in SDK</Button>
                    </Box>
                </Box>

                <Box style={{fontSize: "12px"}}>

                    <TextareaAutosize id="priceConfigIdsTextArea"
                                      style={{minHeight: "200px", minWidth: "100%", maxWidth: "100%", padding: "16px"}}
                                      value={formatMetadataToDisplay(getValues())}
                    />
                </Box>
            </PanelBox>}
        </>
    };


    return <Box>
        <PanelBox sx={{p: 4, m: 2, gap: 4}}>

            <ResponsiveBox sx={{gap: 4}}>
                <Box sx={{flex: 1}}>
                    <MultiSelectAssetPair selectedAssetPairs={assetPair} availableTokenPairs={availableTokenPairs}
                                          control={control} setAssetPairs={(e: any) => selectAssetPair(e)}/>
                    {RenderStep2()}
                </Box>
                <Box sx={{flex: 1}}>
                    {RenderStep3()}
                    {RenderStep4()}
                </Box>
            </ResponsiveBox>

            <LoadingButton disabled={isSyncingData} loading={false} onClick={generatePriceTrajectories} fullWidth
                           variant={"contained"}>Generate Price Trajectories</LoadingButton>

        </PanelBox>

        <PanelBox sx={{p: 2, m: 2, gap: 2}}>
            <Box sx={{p: 2, display: "flex", justifyContent: "space-between"}}>
                <span
                    style={{fontSize: "16px", fontWeight: "bold"}}>{generatedAssetPair} Price Trajectories Graph</span>

                {/*<Box>*/}
                {/*    <span style={{fontSize: "13px"}}> Show all historical data</span>*/}
                {/*    <Checkbox*/}
                {/*        disabled={isGenerating}*/}
                {/*        size="small"*/}
                {/*        onChange={toggleHistoricalDataVisibility} value={isShowAllHistoricalDataEnabled}/>*/}
                {/*</Box>*/}

                <Box>
                    <span style={{fontSize: "13px"}}> Display Y axis</span>
                    <Checkbox
                        disabled={isGenerating}
                        size="small"
                        onChange={toggleDisplayYAxis} checked={isDisplayYAxisEnabled}/>
                </Box>
            </Box>
            {chartError && <Alert severity={"error"}>Error - {chartError}</Alert>}
            {chartData ?
                <AlmanakChart height={"auto"} isLoading={isGenerating} data={chartData}
                              options={chartOptions} hideYAxis={!isDisplayYAxisEnabled}/> :
                <Box sx={{
                    display: "flex",
                    m: 4,
                    fontSize: "18px",
                    fontWeight: "bold",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto"
                }}>

                    {isGenerating ?
                        <CenteredColumnFlexBox>
                            <CenteredRowFlexBox>
                                <div style={{padding: "16px"}}><CircularProgress/></div>
                                <div>Generating Price Trajectories</div>
                            </CenteredRowFlexBox>
                            <div style={{fontSize: "14px", textAlign: "center", marginBottom: "16px"}}>This might take a
                                while...
                            </div>
                        </CenteredColumnFlexBox> :
                        <Box sx={{m: 4}}>Please generate price trajectories first</Box>}

                </Box>}

            {chartData && <CenteredRowFlexBox sx={{justifyContent: "center"}}>
                {selectedAssetPairs?.length === 1 ? marketScenarioOptions.filter(o => o.selected).map(option =>
                        <CenteredRowFlexBox key={option.type} sx={{p: 2}}>
                            <LegendSquare
                                sx={{backgroundColor: initialMarketScenarioOptions?.find(o => o?.type === option.type)?.color}}
                            />
                            {initialMarketScenarioOptions?.find(o => o?.type === option.type)?.title}
                        </CenteredRowFlexBox>
                    ) :
                    selectedAssetPairs?.map((pair: TokenPair, i: number) => <CenteredRowFlexBox key={pair.symbol}
                                                                                                sx={{p: 2}}>
                        <LegendSquare
                            sx={{backgroundColor: graphColorsArray[i]}}
                        />
                        {pair.token0_name} / {pair.token1_name}
                    </CenteredRowFlexBox>)
                }


            </CenteredRowFlexBox>}

        </PanelBox>


        {RenderOptions()}

        {isModalOpen && customizeScenarioModal && <ScenarioCustomizationModal
            isOpen={isModalOpen}
            onSave={onCustomizeScenario}
            onClose={() => setIsModalOpen(false)}
            data={customizeScenarioModal}
        />}

    </Box>
}
