import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Link, matchRoutes, useLocation} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import WalletIcon from '@mui/icons-material/WalletOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LocalLibrary from '@mui/icons-material/LocalLibrary';
import Header from "./Header";
import {AppDrawer} from "./styled";
import {useAppState} from "../../state/AppStateProvider";
import {CircularProgress, styled} from "@mui/material";
import {useState} from "react";
import {getDocsAccessToken} from "../../api/hasura";
import SlideshowIcon from '@mui/icons-material/Slideshow';


const menuItems1 = [
    {title: "Home", icon: HomeIcon, route: "/"},
    {title: "Price Simulator", icon: AutoGraphIcon, route: "/price-simulation"}
]

const menuItems2 = [
    {title: "Simulations", icon: HistoryIcon, route: "/simulation-history"},
    // {title: "Wallets", icon: WalletIcon, route: "/wallets"},
    // {title: "Simulation results", icon: DescriptionIcon, route: "/simulation-results"},
]

const lowerMenuItems = [
    // {title: "Price Simulator", icon: AutoGraphIcon, route: "/price-simulation"}
    {title: "Demo", icon: SlideshowIcon, route: "/demo"}
]
const strategyLibraryItem = {title: "Strategies", icon: LocalLibrary, route: "/strategy-library"}

const ThemedLink = styled(Link)(({theme}) => ({
    textDecoration: "none",
    color: theme.palette.text.primary
}));


export const fullScreenRoutes = ['/simulation/:id', '/simulation/:id/results', '/simulation/:id/agent/:id', '/simulation/:id/agent/:id/strategies', '/simulation/:id/results/agent/:id'];

export const openAlmanakDocs = async (subUrl?: string) => {
    const response = await getDocsAccessToken();
    if (response.customToken) {
        const isStageEnv = process.env.REACT_APP_WEB3AUTH_NETWORK === "testnet";
        const stageUrl = "https://pde-docs.web.app/"; //stage
        const prodUrl = "https://docs.almanak.co/"; //prod
        const url = isStageEnv ? stageUrl : prodUrl;
        window.open(`${url}${subUrl ? subUrl : ""}?token=${response.customToken}`, "_blank")
    }
}
const LDKeyStrategyLibraryWidget = "frontend_strategy-library-widget"

export default function Sidebar() {
    const location = useLocation()
    const {user, setSnackBar, featureFlags} = useAppState();
    const isUserWhitelisted = user?.isWhitelisted;

    const [isDocsTokenGenerating, setIsDocsTokenGenerating] = useState(false);
    const docsItem = {title: "Docs", icon: AssignmentIcon, route: "/docs"};

    const isStrategyLibraryEnabled = isUserWhitelisted && featureFlags?.[LDKeyStrategyLibraryWidget];

    const openDocs = async () => {
        setIsDocsTokenGenerating(true);
        try {
            await openAlmanakDocs();
        } catch (err: any) {
            setSnackBar({open: true, message: "Could not open docs, please contact support", severity: "error"});
        } finally {
            setIsDocsTokenGenerating(false);
        }
    }


    const isOnboarded = user?.isOnboarded;
    const isWhitelisted = user?.isWhitelisted;
    const isAuthenticated = !!localStorage.getItem("accessToken") && !!user?.publicKey;
    const matchedRoute = matchRoutes(fullScreenRoutes.map(r => {
        return {path: r}
    }), location)?.[0]
    const isFullScreenRoute = fullScreenRoutes.includes(matchedRoute?.route.path!)

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <Header/>
            {!isFullScreenRoute && (isOnboarded || isAuthenticated) ? <AppDrawer
                variant="permanent"
                anchor="left"
            >
                <List>
                    {menuItems1.map((item, index) => (
                        <ThemedLink key={item.title} to={item.route}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {<item.icon/>}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItemButton>
                            </ListItem>
                        </ThemedLink>
                    ))}
                    {isStrategyLibraryEnabled && <ThemedLink to={strategyLibraryItem.route}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {<strategyLibraryItem.icon/>}
                                </ListItemIcon>
                                <ListItemText primary={strategyLibraryItem.title}/>
                            </ListItemButton>
                        </ListItem>
                    </ThemedLink>
                    }
                    {isUserWhitelisted && menuItems2.map((item, index) => (
                        <ThemedLink key={item.title} to={item.route}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {<item.icon/>}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItemButton>
                            </ListItem>
                        </ThemedLink>
                    ))}
                </List>
                <Divider/>
                <List>

                    {isUserWhitelisted && lowerMenuItems.map((item, index) => (
                        <ThemedLink to={item.route} key={item.title}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {<item.icon/>}
                                    </ListItemIcon>
                                    <ListItemText primary={item.title}/>
                                </ListItemButton>
                            </ListItem>
                        </ThemedLink>
                    ))}

                    <ListItem disabled={isDocsTokenGenerating} key={docsItem.title} disablePadding onClick={openDocs}>
                        <ListItemButton>
                            <ListItemIcon>
                                {<docsItem.icon/>}
                            </ListItemIcon>
                            <ListItemText primary={docsItem.title}/>
                            {isDocsTokenGenerating && <CircularProgress size={18}/>}
                        </ListItemButton>
                    </ListItem>
                </List>
            </AppDrawer> : null}

        </Box>
    );
}
