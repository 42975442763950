import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {drawerWidth} from "../../constants";

export const PageContentContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-left: ${drawerWidth}px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
`
