import {useEffect, useState} from "react";
import {getGroupSimulations, GroupSimulation, updateGroupSimulation} from "../../api/hasura";
import {
    Box, Button, CircularProgress,
    LinearProgress,
    Paper,
    TableContainer,
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {SimulationStatus} from "./SimulationStatus";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import {Refresh} from "@mui/icons-material";
import {formatDateValue} from "../simulation/create-simulation/PoolDetails";
import {PageContentContainer} from "../styled/styled";
import {useAppState} from "../../state/AppStateProvider";
import {ESimulationStatus} from "../../state/types";
import {SimulationTitleModal} from "../../modals/SimulationTitleModal";

export type SimulationItem = {
    id: string;
    status: ESimulationStatus;
    description: string;
    created_at: string;
}
export const NoDataInTable = () => {
    return <Box sx={{
        fontSize: "18px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        m: 4
    }}>
        No data
    </Box>
}

const SimulationHistory = () => {

    const {setSelectedSimulation, setSnackBar} = useAppState();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<SimulationItem[] | null>(null)
    const [isLoading, setIsLoading] = useState(false);
    const [simulationToRename, setSimulationToRename] = useState<GroupSimulation | null>(null);

    const fetchSimulations = async () => {
        setIsLoading(true);
        const data = await getGroupSimulations();
        if (data.data.errors && data.data.errors?.length !== 0) {
            throw new Error(data.data.errors?.[0].message)

        }
        if (data.data.data?.group_simulations) {
            setTableData(data.data.data.group_simulations)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchSimulations();
    }, [])


    const onRenameSimulation = async (title: string) => {
        try {
            const simulation = await updateGroupSimulation(simulationToRename?.id!,
                {
                    frontend_state: {
                        ...simulationToRename?.frontend_state,
                        title
                    }
                }
            )
            setSelectedSimulation(simulation);
            setSnackBar({open: true, message: "Simulation renamed", severity: "success"});
        } catch (error: any) {
            setSnackBar({open: true, message: error.message, severity: "error"});
        } finally {
            setSimulationToRename(null);
            fetchSimulations();
        }
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'Simulation ID'},
        {
            field: 'description',
            headerName: 'Title',
            flex: 1,
            renderCell: (item) => <span>{item.row.frontend_state?.title ?? item.row.metadata?.title ??  "-"}</span>
        },
        {
            field: 'created_at', headerName: 'Created', flex: 1, minWidth: 140,
            renderCell: (item) => <span>{formatDateValue(item.row.created_at, true)}</span>
        },
        {
            field: 'agent_configs', headerName: 'Agents', flex: 0,
            renderCell: (item) => <span>{item.row.frontend_state?.agentConfigs?.length}</span>
        },
        // {
        //     field: 'marketScenarios', headerName: 'Scenarios', flex: 0,
        //     renderCell: (item) => <span>{item.row.frontend_state.selectedMarketScenarios?.filter((s: any) => s.selected)?.length}</span>
        // },
        {
            field: 'trajectories', headerName: 'Price Trajectories', flex: 0,
            renderCell: (item) => <span>{item.row.price_configs?.length ?? 0}</span>
        },
        {
            field: "status",
            headerName: "Status",
            width: 120,
            renderCell: (item) => <SimulationStatus status={item.row.status}/>
        },
        {
            field: "action",
            headerName: "",
            width: 100,
            renderCell: (item) => <Button size="small"
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              setSimulationToRename(item.row)
                                          }}>Rename</Button>
        }
    ];

    const onSimulationSelect = (item: SimulationItem) => {
        setSelectedSimulation(item as GroupSimulation);
        if (item.status === ESimulationStatus.Pending) {
            navigate(`/simulation/${item.id}`);
        } else {
            navigate(`/simulation/${item.id}/results`);

        }
    }
    return (
        <PageContentContainer>
            <Box sx={{display: "flex", gap: 1, alignItems: "center"}}>
                <h2>
                    Simulations
                </h2>
                <Box>
                    <IconButton onClick={fetchSimulations} size="small">{isLoading ? <CircularProgress size={20}/> :
                        <Refresh/>}</IconButton>
                </Box>
            </Box>

            {isLoading && <LinearProgress sx={{width: "100%"}}/>}

            <TableContainer component={Paper}>

                {tableData && <DataGrid
                    onRowClick={(item) => onSimulationSelect(item.row)}
                    rows={tableData}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10},
                        },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                />}
            </TableContainer>

            {simulationToRename?.id &&
            <SimulationTitleModal isOpen={!!simulationToRename?.id} onClose={() => setSimulationToRename(null)}
                                  onSave={onRenameSimulation}
                                  title={simulationToRename?.frontend_state?.title ?? ""}/>}

        </PageContentContainer>
    );
}

export default SimulationHistory;
