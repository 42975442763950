import React, { createContext, useContext, useState } from "react";
import { TradeData } from "../utils/commonUtils";
import {
  AgentInterface,
  AppStateContextValues,
  AppStateProviderProps,
  SimulationMetadata,
  SnackbarData,
  TokenDayData,
  TokenPair,
} from "./types";
import {
  MovingAverageData,
  TokenData,
} from "../components/almanak-chart/graphTypes";
import { Web3Auth } from "@web3auth/modal";
import { SafeEventEmitterProvider } from "@web3auth/base";
import { EGraphDateRange, volumeChartMock } from "../constants";
import { UserData } from "../hooks/useAuth";
import { GroupSimulation } from "../api/hasura";
import { TokenDetails } from "../modals/AddWalletModal";
import { UseFormReturn } from "react-hook-form";
import {LDFlagSet} from "launchdarkly-js-sdk-common";

const AppStateContext = createContext<null | AppStateContextValues>(null);

export type FeatureFlags = LDFlagSet;

export const LDKeyUserApiKeyPanel = "user-api-key-panel";
export const LDKeyGlobalMaintenanceMode = "platform-maintenance-mode";
export const LDKeyPriceSimulatorMaintanance = "price-sim-maintenance-mode";
export const LDKeySimulatorMaintanance = "simulator-maintenance-mode";
export const LDKeyStrategyLibraryMaintenance = "strategy-library-maintenance-mode";

const initialProdFeatureFlags = {
  "user-api-key-panel": false,
  "platform-maintenance-mode": false,
  "price-sim-maintenance-mode": false,
  "frontend_price-sim-direct": false,
};

const initialStageFeatureFlags = {
  "user-api-key-panel": true,
  "platform-maintenance-mode": true,
  "price-sim-maintenance-mode": true,
  "frontend_price-sim-direct": true,
};

const initialFlags: FeatureFlags = process.env.REACT_APP_ENVIRONMENT === "production" ? initialProdFeatureFlags : initialStageFeatureFlags;

type ThemeOptions = "light" | "dark";
export const AppStateProvider = ({ children }: AppStateProviderProps) => {
  const systemPreferredTheme = window.matchMedia("(prefers-color-scheme: dark)")
    .matches
    ? "dark"
    : "light";
  const selectedTheme = (localStorage.getItem("theme") ??
    systemPreferredTheme) as ThemeOptions;
  const [selectedSimulation, setSelectedSimulation] = useState<
    GroupSimulation | undefined
  >();
  const [simulationFormController, setSimulationFormController] = useState<
    UseFormReturn<SimulationMetadata> | undefined
  >();
  const [web3Auth, setWeb3Auth] = useState<Web3Auth | null>(null);
  const [provider, setProvider] = useState<SafeEventEmitterProvider | null>(
    null
  );
  const [snackBar, setSnackBar] = useState<SnackbarData | null>(null);
  const [user, setUser] = useState<UserData | null>(null);
  const [isWalletConnecting, setIsWalletConnecting] = useState(true);
  const [isLeftSideExpanded, setIsLeftSideExpanded] = useState(true);
  const [availableTokens, setAvailableTokens] = useState<TokenDetails[] | null>(
    null
  );
  const [selectedAgent, setSelectedAgent] = useState<AgentInterface | null>(
    null
  );
  const [availableTokenPairs, setAvailableTokenPairs] = useState<
    TokenPair[] | null
  >(null);
  const [reloadCreditBalance, setReloadCreditBalance] = useState(true);

  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(initialFlags);

  //TODO: will this be necessary? cleanup required
  const [isGraphLoading, setIsGraphLoading] = useState(false);
  const [theme, setTheme] = useState<ThemeOptions>(selectedTheme);
  const [selectedToken, setSelectedToken] = useState<TokenData | null>(null);
  const [selectedGraphRange, setSelectedGraphRange] = useState<EGraphDateRange>(
    EGraphDateRange["1M"]
  );
  const [selectedTokenGraphData, setSelectedTokenGraphData] = useState<
    TokenDayData[] | null
  >(volumeChartMock);
  const [shortMovingAverageData, setShortMovingAverageData] = useState<
    MovingAverageData[] | null
  >(null);
  const [longMovingAverageData, setLongMovingAverageData] = useState<
    MovingAverageData[] | null
  >(null);
  const [executedTradesGraphData, setExecutedTradesGraphData] = useState<
    TradeData[] | null
  >(null);

  const [cliAuthPort, setCliAuthPort] = useState<number | null>(null);
  const [cliAuthentication, setCliAuthentication] = useState<boolean>(false);

  const [simulatorPriceTrajectoryHashes, setSimulatorPriceTrajectoryHashes] = useState<string[] | null>(null);

  const changeTheme = () => {
    if (theme === "light") {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
  };
  let value = {
    selectedSimulation,
    setSelectedSimulation,
    selectedAgent,
    setSelectedAgent,
    web3Auth,
    setWeb3Auth,
    provider,
    setProvider,
    snackBar,
    setSnackBar,
    user,
    setUser,
    isWalletConnecting,
    setIsWalletConnecting,
    theme,
    changeTheme,
    isLeftSideExpanded,
    setIsLeftSideExpanded,
    availableTokens,
    setAvailableTokens,
    availableTokenPairs,
    setAvailableTokenPairs,
    simulationFormController,
    setSimulationFormController,
    reloadCreditBalance,
    setReloadCreditBalance,
    cliAuthPort,
    setCliAuthPort,
    cliAuthentication,
    setCliAuthentication,
    featureFlags,
    setFeatureFlags,

    //TODO: remove these when refactoring the simulator
    simulatorPriceTrajectoryHashes,
    setSimulatorPriceTrajectoryHashes,
    //----------------------------------------------

    //TODO: will this be necessary? cleanup required
    isGraphLoading,
    setIsGraphLoading,
    selectedToken,
    setSelectedToken,
    selectedGraphRange,
    setSelectedGraphRange,
    selectedTokenGraphData,
    setSelectedTokenGraphData,
    shortMovingAverageData,
    setShortMovingAverageData,
    executedTradesGraphData,
    setExecutedTradesGraphData,
    longMovingAverageData,
    setLongMovingAverageData,
  };
  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = () => {
  const appStateContext = useContext(AppStateContext);
  if (appStateContext === null) {
    throw new Error("App State Context is not available");
  }

  return appStateContext;
};
